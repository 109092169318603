import { Center, Checkbox, GridItem, Spinner } from '@chakra-ui/react';
import { StringOrNumber } from '@chakra-ui/utils';
import { FunctionComponent, useMemo } from 'react';
import type {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { Column } from 'react-table';
import { DataTable } from '../../../components/DataTable';
import { FormInput, SelectFormInput } from '../../../components/Input';
import { useData } from '../../../providers/DataProvider';
import {
  DetailedPort,
  PortCode,
  PortCodeType,
  PortType,
  System,
} from '../../../types';

interface PortFieldsProps {
  register: UseFormRegister<DetailedPort>;
  errors: FieldErrors<DetailedPort>;
  setValue: UseFormSetValue<DetailedPort>;
  control: Control<any>;
  watch: UseFormWatch<DetailedPort>;
  port: DetailedPort;
  systems: System[];
}
export const PortFields: FunctionComponent<PortFieldsProps> = ({
  register,
  errors,
  control,
  watch,
  setValue,
  port,
}) => {
  const { countries } = useData();
  const portType = watch('portType') in PortType ? watch('portType') : null;
  const countryId =
    watch('country') == null ? null : watch('country').countryID;

  const portCodeColumns = useMemo<Column<PortCode>[]>(
    () => [
      {
        Header: 'Name',
        accessor: (item) => item.system.name,
      },
      {
        Header: 'Code',
        accessor: (item) => (
          <FormInput
            hideLabel={true}
            register={register}
            errors={errors}
            accessor={`portCodes[${
              port?.portCodes?.findIndex(
                (code) => code.portCodeID === item.portCodeID,
              ) ?? 0
            }].code`}
          />
        ),
      },
      { Header: 'Code Type', accessor: (item) => PortCodeType[item?.type] },
    ],
    [errors, port, register],
  );

  if (countries.isLoading) {
    return (
      <GridItem colSpan={2}>
        <Center>
          <Spinner size="xl" />
        </Center>
      </GridItem>
    );
  }

  return (
    <>
      <GridItem>
        <FormInput
          label="Name"
          register={register}
          errors={errors}
          accessor={'name'}
          isRequired={true}
          registerOptions={{
            maxLength: { value: 50, message: 'Maximum of 50 characters' },
          }}
        />
      </GridItem>
      <GridItem>
        <SelectFormInput
          label="Type"
          accessor={'portType'}
          placeholder="Select a Type"
          isRequired={true}
          control={control}
          options={[
            { label: 'Air', value: '4' },
            { label: 'Sea', value: 1 },
          ]}
          defaultValue={
            portType && {
              label: PortType[portType],
              value: portType,
            }
          }
          onChange={(value) => {
            const enumVal = Number.parseInt(
              (
                value as { label: string; value: StringOrNumber }
              ).value.toString(),
            );
            setValue('portType', enumVal);

            if (
              port.portCodes.every((pc) => pc.type === port.portCodes[0].type)
            ) {
              port.portCodes.forEach(
                (pc) =>
                  (pc.type =
                    enumVal === PortType.Sea
                      ? PortCodeType.UNLO
                      : PortCodeType.IATA),
              );
            }
          }}
        />
      </GridItem>
      <GridItem>
        <SelectFormInput
          label="Country"
          accessor={'country.countryID'}
          control={control}
          placeholder="Select a Country"
          isRequired={true}
          defaultValue={
            countryId && {
              label: countries.data?.find(
                (country) => country.countryID === countryId,
              )?.name,
              value: countryId,
            }
          }
          options={countries.data?.map((country) => ({
            label: country.name,
            value: country.countryID,
          }))}
        />
      </GridItem>
      <GridItem>
        <FormInput
          label="City"
          register={register}
          errors={errors}
          accessor={'city'}
        />
      </GridItem>
      <GridItem>
        <FormInput
          label="Geo Location"
          register={register}
          errors={errors}
          accessor={'geoLocation'}
          placeholder="e.g. 53.5446991423739, 9.996696213565075"
          isRequired={true}
        />
      </GridItem>
      <GridItem>
        <FormInput
          label="Location Code"
          register={register}
          errors={errors}
          accessor={'locationCode'}
          registerOptions={{
            maxLength: { value: 5, message: 'Maximum of 5 characters' },
          }}
        />
      </GridItem>
      <GridItem colSpan={2}>
        <DataTable<PortCode>
          data={port?.portCodes}
          isLoading={false}
          columns={portCodeColumns}
          fetchFailed={false}
          variant="sticky"
          enableFilters={false}
          showLoadAllButton={false}
        />
      </GridItem>

      <GridItem colSpan={2}>
        <Checkbox {...register('isActive')}>Is Active</Checkbox>
      </GridItem>
    </>
  );
};

import { DeleteIcon } from '@chakra-ui/icons';
import {
  Button,
  HStack,
  Skeleton,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import {
  FunctionComponent,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ActionModal } from '../../components/ActionModal';
import { CheckIcon, CloseIcon } from '../../components/Icons';
import {
  FormInput,
  MultiSelectFormInput,
  SelectFormInput,
} from '../../components/Input';
import { HeadingCard, MainCard } from '../../components/Layout';
import { ErrorResult } from '../../components/Result';
import { UnsavedChangesModal } from '../../components/UnsavedChangesModal';
import { useErrorToast } from '../../hooks/useErrorToast';
import { useApi } from '../../providers/ApiProvider';
import { useAuthentication } from '../../providers/AuthenticationProvider';
import { useAuthorization } from '../../providers/AuthorizationProvider';
import { useData } from '../../providers/DataProvider';
import { User, UserRole } from '../../types';
import { getHttpStatusDescription } from '../../utils/httpStatus';
import { routes } from '../../utils/routes';

const errorTitle = 'Saving your changes failed, please try again';

export const UserEditPage: FunctionComponent = () => {
  const { id } = useParams();
  const { getApi, putApi } = useApi();
  const { user: authUser, reloadUser } = useAuthentication();
  const { hasRole, userRole } = useAuthorization();
  const errorToast = useErrorToast();
  const navigate = useNavigate();
  const { countries, roles } = useData();
  const [statusCode, setStatusCode] = useState<number>(0);
  const location = useLocation();
  const [user, setUser] = useState<User | undefined>(undefined);

  const getUser = useCallback(async () => {
    const result = await getApi(`users/${id}`);
    setStatusCode(result.status);
    if (result.ok) {
      const res = await result.json();
      setUser(res);
    }
  }, [getApi, id]);

  const isSelf = user?.userID === authUser?.userID;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenBack,
    onOpen: onOpenBack,
    onClose: onCloseBack,
  } = useDisclosure();

  const {
    control,
    handleSubmit,
    register,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    if (user == null) {
      getUser();
    }
  }, [getUser, user]);

  const saveChanges = useCallback(
    async (values: FieldValues) => {
      let response;
      try {
        response = await putApi(`users/${id}`, {
          name: values.name,
          userCountry: countries.data?.find(
            (country) => country.countryID === values.country,
          ),
          assignedCountries: countries.data?.filter((country) =>
            values.assignedCountries?.includes(country.countryID),
          ),
          role: roles.data?.find((role) => role.id === values.role),
        });
      } catch (e) {
        errorToast({ title: errorTitle });
        return false;
      }

      if (response.ok) {
        if (id === authUser?.userID) await reloadUser();
        if (location.state.isAccountEdit) {
          navigate(routes.account, {
            state: { backTo: location.state?.backTo },
          });
        } else {
          navigate(routes.userManagement.details(id!), {
            state: { backTo: location.state?.backTo },
          });
        }
      } else {
        errorToast({
          title: getHttpStatusDescription(response.status),
        });
      }
    },
    [
      putApi,
      id,
      countries,
      roles,
      authUser,
      reloadUser,
      navigate,
      location,
      errorToast,
    ],
  );

  const onDeactivateUser = () => {
    saveChanges({
      ...getValues(),
      role: roles.data?.find((role) => role.role === UserRole.NONE)?.id,
    });
  };

  const filteredRoles = useMemo(
    () =>
      roles.data?.filter((role) => {
        if (role.role === UserRole.NONE) {
          return false;
        }
        if (hasRole([UserRole.GLOBAL_ADMINISTRATOR])) {
          return true;
        }
        if (hasRole([UserRole.ORGANIZATION_MANAGER])) {
          return role.role !== UserRole.GLOBAL_ADMINISTRATOR;
        }
        if (hasRole([UserRole.REGION_MANAGER])) {
          return (
            role.role !== UserRole.GLOBAL_ADMINISTRATOR &&
            role.role !== UserRole.ORGANIZATION_MANAGER &&
            role.role !== UserRole.REGION_MANAGER
          );
        }
        return false;
      }),
    [hasRole, roles],
  );

  const filteredCountries = useMemo(() => {
    if (
      hasRole([UserRole.GLOBAL_ADMINISTRATOR, UserRole.ORGANIZATION_MANAGER])
    ) {
      return user?.organization?.assignedCountries;
    }
    if (hasRole([UserRole.REGION_MANAGER])) {
      return authUser?.assignedCountries;
    }
    return [];
  }, [user, authUser, hasRole]);

  const content = () => {
    if (user == null || countries.isLoading || roles.isLoading) {
      return <Skeleton height="200px" />;
    }

    if (countries.data == null || roles.data == null || statusCode !== 200) {
      return <ErrorResult statusCode={statusCode} />;
    }

    if (
      userRole < user.role.role ||
      (userRole === user.role.role &&
        userRole === UserRole.REGION_MANAGER &&
        user.userID !== authUser?.userID)
    ) {
      return <ErrorResult statusCode={403} />;
    }

    return (
      <form onSubmit={handleSubmit(saveChanges)}>
        <Stack spacing="5">
          <FormInput
            accessor="name"
            label="Name"
            register={register}
            errors={errors}
            defaultValue={user.name}
            isDisabled={isSubmitting}
            isRequired={true}
          />
          <SelectFormInput
            accessor="country"
            label="Country"
            defaultValue={{
              label: user.userCountry?.name,
              value: user.userCountry?.countryID,
            }}
            isDisabled={isSubmitting}
            isRequired={true}
            control={control}
            controllerDefaultValue={user.userCountry?.countryID}
            options={countries.data.map((country) => ({
              label: country.name,
              value: country.countryID,
            }))}
          />
          {(hasRole([
            UserRole.GLOBAL_ADMINISTRATOR,
            UserRole.ORGANIZATION_MANAGER,
          ]) ||
            (hasRole([UserRole.REGION_MANAGER]) &&
              authUser?.userID !== user.userID)) && (
            <MultiSelectFormInput
              accessor="assignedCountries"
              label="Assigned countries"
              control={control}
              controllerDefaultValue={
                user.role.role === UserRole.ORGANIZATION_MANAGER
                  ? user.organization?.assignedCountries.map(
                      (country) => country.countryID,
                    )
                  : user.assignedCountries.map((country) => country.countryID)
              }
              defaultValue={
                user.role.role === UserRole.ORGANIZATION_MANAGER
                  ? user.organization?.assignedCountries.map((country) => ({
                      label: country.name,
                      value: country.countryID,
                    }))
                  : user.assignedCountries.map((country) => ({
                      label: country.name,
                      value: country.countryID,
                    }))
              }
              isDisabled={
                isSubmitting ||
                user?.role.role === UserRole.ORGANIZATION_MANAGER
              }
              options={filteredCountries?.map((country) => ({
                label: country.name,
                value: country.countryID,
              }))}
            />
          )}
          {authUser?.userID !== user.userID && (
            <SelectFormInput
              accessor="role"
              label="Role"
              defaultValue={{ label: user.role.name, value: user.role.id }}
              isDisabled={isSubmitting}
              isRequired={true}
              control={control}
              controllerDefaultValue={user.role.id}
              options={filteredRoles?.map((role) => ({
                label: role.name,
                value: role.id,
              }))}
            />
          )}
        </Stack>
      </form>
    );
  };

  return (
    <Stack>
      <ActionModal
        onCancel={onClose}
        onSuccess={onClose}
        onActionClick={onDeactivateUser}
        isOpen={isOpen}
        modalText={`Are you sure you want to deactivate ${user?.name}?`}
        modalHeader="Deactivate User"
        actionButtonText="Deactivate"
      />
      <UnsavedChangesModal
        isOpen={isOpenBack}
        onCancel={onCloseBack}
        onSuccess={onCloseBack}
        route={
          location.state.isAccountEdit
            ? routes.account
            : routes.userManagement.details(id!)
        }
        backTo={location.state?.backTo}
      />
      <HeadingCard
        heading={user?.name}
        direction="row"
        align="center"
        justify="space-between"
        onBack={onOpenBack}
      >
        <HStack>
          {user && user?.role.role !== UserRole.NONE && !isSelf && (
            <Button
              colorScheme="red"
              size="xs"
              onClick={onOpen}
              rightIcon={<DeleteIcon w="4" h="4" />}
            >
              Deactivate
            </Button>
          )}

          <Button
            as={Link}
            to={
              location.state.isAccountEdit
                ? routes.account
                : routes.userManagement.details(id!)
            }
            state={{ backTo: location.state?.backTo }}
            colorScheme="white"
            size="xs"
            _hover={{ bgColor: 'grey.600' }}
            rightIcon={<CloseIcon w="5" h="5" />}
          >
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            size="xs"
            onClick={handleSubmit(saveChanges)}
            isLoading={isSubmitting}
            rightIcon={<CheckIcon w="5" h="5" />}
          >
            Save
          </Button>
        </HStack>
      </HeadingCard>
      <MainCard heading="Edit User">{content()}</MainCard>
    </Stack>
  );
};

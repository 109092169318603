import { Country, Port, PortType } from '../../types';
import { MultiselectOption } from '../../types/MultiSelectOption';

export const formatAirportsToOptions = (
  fullPorts?: Port[],
  originCountry?: Country,
): MultiselectOption[] => {
  if (!fullPorts) return [];

  return fullPorts
    .filter(
      (port) =>
        port.portType === PortType.Air &&
        (originCountry?.code == null ||
          port?.country == null ||
          port.country?.countryID === originCountry?.countryID),
    )
    .map((port) => ({
      label: `${port.iataCode} - ${port.name}`,
      value: port.country.code + port.unloCode,
    }))
    .sort((a, b) => (a.label < b.label ? -1 : 1));
};

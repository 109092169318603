import { PortCode, PortCodeType } from '../../types';

export const getUnlocode = (portcodes: PortCode[] | undefined) =>
  portcodes?.find(
    (p) => p.system.code === 'CGN' && p.type === PortCodeType.UNLO,
  );
export const getIataCode = (portcodes: PortCode[] | undefined) =>
  portcodes?.find(
    (p) => p.system.code === 'CGN' && p.type === PortCodeType.IATA,
  );

import { ArrowRightIcon } from '@chakra-ui/icons';
import { Divider, GridItem, SimpleGrid, Text } from '@chakra-ui/layout';
import { FormLabel, Grid, Stack } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import shallow from 'zustand/shallow';
import { useSeaLclFilters } from '../../../hooks/useSeaLclFilters';
import { useData } from '../../../providers/DataProvider';
import { NumberFormInput } from '../../Input';
import type { FieldProps } from './LocalChargeFields';

interface SeaLclChargeFieldsProps extends FieldProps {
  getValues: (key?: string) => any;
}

const atLeastOneErrorMessage: string =
  'Either Per CBM or Per 1000kg must have a value';

export const SeaLclChargeFields: FunctionComponent<SeaLclChargeFieldsProps> = ({
  control,
  getValues,
}) => {
  const { countries } = useData();

  const { originCountryId, destinationCountryId } = useSeaLclFilters(
    (state) => ({
      ports: state.ports,
      originCountryId: state.originCountryId,
      destinationCountryId: state.destinationCountryId,
    }),
    shallow,
  );

  const originCountryIdValue =
    getValues('origin.country.countryID') ?? originCountryId;
  const destinationCountryIdValue =
    getValues('destination.country.countryID') ?? destinationCountryId;
  const originUnloCode = getValues('origin.unloCode');
  const destinationUnloCode = getValues('destination.unloCode');

  return (
    <Stack spacing="4">
      <Stack
        direction={{ base: 'column', md: 'row' }}
        align="center"
        justifyContent="space-between"
      >
        <SimpleGrid columns={2} w={{ base: 'full', lg: '50%' }} rowGap="4">
          <FormLabel mb="0">Country</FormLabel>
          <Text fontSize="xs">
            {
              countries.data?.find(
                (country) => country.countryID === originCountryIdValue,
              )?.name
            }
          </Text>
          <FormLabel mb="0">Port</FormLabel>
          <Text fontSize="xs">{originUnloCode}</Text>
        </SimpleGrid>
        <ArrowRightIcon color="grey.400" width="32" />
        <SimpleGrid columns={2} w={{ base: 'full', lg: '50%' }} rowGap="4">
          <FormLabel mb="0">Country</FormLabel>
          <Text fontSize="xs">
            {
              countries.data?.find(
                (country) => country.countryID === destinationCountryIdValue,
              )?.name
            }
          </Text>
          <FormLabel mb="0">Port</FormLabel>
          <Text fontSize="xs">{destinationUnloCode}</Text>
        </SimpleGrid>
      </Stack>
      <Divider borderColor="grey.900" borderWidth="1px" opacity="unset" />
      <Stack>
        <Grid gap={{ base: 8, lg: 10 }} templateColumns={'repeat(3, 1fr)'}>
          <GridItem>
            <NumberFormInput
              label="Min"
              accessor="min"
              control={control}
              precision={2}
              step={1.0}
              isRequired={true}
            />
          </GridItem>
          <GridItem>
            <NumberFormInput
              label="Per CBM"
              accessor="perCbm"
              control={control}
              precision={2}
              step={1.0}
              validate={(value: string) =>
                value !== '' ||
                getValues('per1000kg') !== '' ||
                atLeastOneErrorMessage
              }
            />
          </GridItem>
          <GridItem>
            <NumberFormInput
              label="Per 1000kg"
              accessor="per1000kg"
              control={control}
              precision={2}
              step={1.0}
              validate={(value: string) =>
                value !== '' ||
                getValues('perCbm') !== '' ||
                atLeastOneErrorMessage
              }
            />
          </GridItem>
        </Grid>
      </Stack>
    </Stack>
  );
};

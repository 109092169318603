import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CalculatorTariff } from '../../../../types/CalculatorTariff';
import { MethodTariff } from '../../../../types/MethodTariff';
import { TariffTruckingDto } from '../../../../types/api/TariffTruckingDto';
import { getRatesFormFiltered } from '../../TariffCreateRatesPage/utils/getRatesFormFiltered';
import { transformBracketsToRateForm } from '../utils/transformBracketsToRateForm';
import { useDynamicTariffTable } from './useDynamicTariffTable';

const INITIAL_ROW_COUNT = 10;
const INITIAL_COLUMN_COUNT = 8;
const MIN_COLUMN_COUNT = 2;
const MIN_ROW_COUNT = 2;

const getInitialCount = (
  initialNumber: number,
  searchObject: Record<string, unknown> | undefined,
  searchKey: string,
  minCount: number,
) => {
  let initialCount = initialNumber;
  if (searchObject) {
    const foundItems = Object.keys(searchObject).filter((formKey) =>
      formKey.includes(searchKey),
    ).length;
    initialCount = foundItems > 1 ? foundItems : minCount;
  }
  return initialCount;
};

export const useDynamicTariffTableForm = (
  method: MethodTariff,
  calculator: CalculatorTariff,
  country: string,
  rate?: TariffTruckingDto | null,
  savedForm?: Record<string, unknown>,
  isEdit: boolean = false,
) => {
  const initialColumnCount = getInitialCount(
    INITIAL_COLUMN_COUNT,
    savedForm,
    'header-',
    MIN_COLUMN_COUNT,
  );
  const initialRowCount = getInitialCount(
    INITIAL_ROW_COUNT,
    savedForm,
    'zone-',
    MIN_ROW_COUNT,
  );
  const [columnCount, setColumnCount] = useState(
    savedForm ? initialColumnCount + 1 : initialColumnCount,
  );
  const [rowCount, setRowCount] = useState(initialRowCount);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    control,
    setValue,
    setError,
  } = useForm();

  const { columns, rows } = useDynamicTariffTable(
    errors,
    register,
    control,
    isEdit,
    columnCount,
    rowCount,
    method,
    calculator,
    country,
    savedForm,
    rate?.weightBrackets,
    rate?.tariffRatesSorted,
    setValue as (fieldName: string, fieldValue: string) => void,
  );

  useEffect(() => {
    if (rate) {
      setColumnCount(rate.weightBrackets.length);
      setRowCount(rate.methodBrackets.length);
      setTimeout(() => reset(transformBracketsToRateForm(rate)));
    }
  }, [rate, reset]);

  useEffect(() => {
    if (savedForm) {
      reset(getRatesFormFiltered(savedForm, rowCount, columnCount));
    } else {
      reset(getRatesFormFiltered(getValues(), rowCount, columnCount));
    }
  }, [columnCount, rowCount, reset, getValues, savedForm]);

  const onAddRow = () => {
    setRowCount(rowCount + 1);
  };

  const onDeleteRow = () => {
    if (rowCount > MIN_ROW_COUNT) {
      setRowCount(rowCount - 1);
    }
  };

  const onAddColumn = () => {
    setColumnCount(columnCount + 1);
  };

  const onDeleteColumn = () => {
    if (columnCount > MIN_COLUMN_COUNT) {
      setColumnCount(columnCount - 1);
    }
  };
  return {
    onAddColumn,
    onAddRow,
    onDeleteColumn,
    onDeleteRow,
    getValues,
    handleSubmit,
    reset,
    rowCount,
    columnCount,
    rows,
    columns,
    setError,
  };
};

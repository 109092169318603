import { TariffTruckingDto } from '../../../../types/api/TariffTruckingDto';
import { ZIP_CODE_SEPARATOR } from '../constants/tariffConstants';

export const transformBracketsToRateForm = (initialRate: TariffTruckingDto) => {
  const initialFormValues: Record<string, string | number | undefined> = {};
  initialRate.weightBrackets.forEach((weightBracket, index) => {
    initialFormValues[`header-${index}`] = weightBracket.value;
  });
  initialRate.methodBrackets.forEach((methodBracket, index) => {
    initialFormValues[`zone-${index}`] =
      `${methodBracket.rangeFrom}${ZIP_CODE_SEPARATOR}${methodBracket.rangeTo}`;
  });
  initialRate.tariffRatesSorted.forEach((tariffRateRow, rowIndex) => {
    tariffRateRow.forEach((tariffRate, columnIndex) => {
      initialFormValues[`${columnIndex}-${rowIndex}`] =
        tariffRate?.value || undefined;
    });
  });
  return initialFormValues;
};

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
} from '@chakra-ui/react';
import React, { type FunctionComponent, type ReactNode } from 'react';
import { Control, Controller } from 'react-hook-form';
import type { FormInputProps } from './FormInput';

interface NumberFormInputProps
  extends Omit<FormInputProps, 'register' | 'errors'>,
    NumberInputProps {
  control?: Control<any>;
  validate?: any;
  useButtons?: boolean;
  wrapperProps?: FormControlProps;
  hideLabel?: boolean;
  borderRadius?: string;
  rightElement?: ReactNode;
  paddingInlineEnd?: string;
  inputFieldColor?: string;
}

export const NumberFormInput: FunctionComponent<NumberFormInputProps> = ({
  control,
  label,
  accessor,
  isRequired,
  isDisabled,
  min = 0,
  max = 1_000_000_000,
  useButtons = false,
  validate,
  wrapperProps,
  hideLabel = false,
  borderRadius = '8',
  rightElement,
  paddingInlineEnd,
  inputFieldColor,
  ...rest
}) => (
  <Controller
    control={control}
    name={accessor}
    rules={{
      required: isRequired,
      validate,
    }}
    render={({ field: { ...restField }, fieldState: { error } }) => (
      <FormControl
        isInvalid={!!error}
        isRequired={isRequired}
        {...wrapperProps}
      >
        <FormLabel htmlFor={accessor} display={hideLabel ? 'none' : 'inherit'}>
          {label}
        </FormLabel>
        <NumberInput
          id={accessor}
          variant="outline"
          bg="grey.900"
          precision={2}
          min={min}
          max={max}
          step={1.0}
          borderRadius={borderRadius}
          disabled={isDisabled}
          height="10"
          {...restField}
          {...rest}
        >
          <NumberInputField
            onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
              e.preventDefault();
              e.target.select();
            }}
            fontSize={rest.fontSize ?? 'xs'}
            fontWeight="500"
            height="inherit"
            borderRadius={borderRadius}
            borderColor="grey.500"
            textAlign="center"
            paddingInlineStart="4"
            paddingInlineEnd={paddingInlineEnd ?? '4'}
            minW="3rem"
            color={inputFieldColor}
            _focus={{ borderColor: 'blue.500' }}
            _hover={{ borderColor: 'grey.400' }}
          />
          {rightElement && <InputRightElement children={rightElement} />}
          {useButtons && (
            <NumberInputStepper color="blue.500" borderColor="grey.500">
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          )}
        </NumberInput>
        <FormErrorMessage>{error && error.message}</FormErrorMessage>
      </FormControl>
    )}
  />
);

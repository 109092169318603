import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import { ActionModal } from './ActionModal';

interface UnsavedChangesModalProps {
  onCancel: () => void;
  onSuccess: () => void;
  isOpen: boolean;
  route: string;
  backTo?: string;
}

export const UnsavedChangesModal: FunctionComponent<
  UnsavedChangesModalProps
> = ({ onCancel, onSuccess, isOpen, route, backTo }) => {
  const navigate = useNavigate();
  return (
    <ActionModal
      onCancel={onCancel}
      onSuccess={onSuccess}
      onActionClick={() =>
        navigate(route, {
          state: {
            backTo,
          },
        })
      }
      isOpen={isOpen}
      modalText="Going back will disregard all unsaved changes!"
      modalHeader="Unsaved Changes"
      actionButtonText="Back"
    />
  );
};

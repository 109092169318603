import { Button } from '@chakra-ui/button';
import { Heading, HStack } from '@chakra-ui/layout';
import { useDisclosure, useTheme } from '@chakra-ui/react';
import { useEffect, useMemo, useState, useCallback } from 'react';
import type { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import type { Column, FilterProps } from 'react-table';
import shallow from 'zustand/shallow';
import { SelectColumnFilter } from '../../../components/DataTable/SelectColumnFilter';
import { conditionalHeader } from '../../../components/DataTable/utils/dataTableUtils';
import { PlusIcon } from '../../../components/Icons';
import { IfApplicableTable } from '../../../components/IfApplicableTable';
import { HeadingCard, Card, MainCard } from '../../../components/Layout';
import { RateTable } from '../../../components/RateTable';
import { UpsertRateModal } from '../../../components/RateTable/UpsertRateModal';
import type { LocalChargeFormValues } from '../../../components/RateTable/UpsertRateModal/LocalChargeFields';
import { useErrorToast } from '../../../hooks/useErrorToast';
import { useLocalFilters } from '../../../hooks/useLocalFilters';
import { useApi } from '../../../providers/ApiProvider';
import { useAuthentication } from '../../../providers/AuthenticationProvider';
import { useAuthorization } from '../../../providers/AuthorizationProvider';
import { useData } from '../../../providers/DataProvider';
import { LocalCharge, RateType, TransportMode } from '../../../types';
import { IfApplicable } from '../../../types/IfApplicable';
import { toDisplayDateFormat } from '../../../utils/formatter';
import { getHttpStatusDescription } from '../../../utils/httpStatus';
import { getTextWidth } from '../../../utils/textWidth';
import { formatChargeDate } from '../utils/dateUtils';
import { checkCanMutateRates } from '../utils/ratePermissions';
import { LocalChargeFilter } from './components/LocalChargeFilter';
import { UpsertIfApplicableRate } from './components/UpsertIfApplicableRate';

const errorTitle = 'Saving your changes failed, please try again';

export interface LocalChargeResponse
  extends Omit<LocalCharge, 'validTo' | 'validFrom'> {
  validFrom: string;
  validTo: string;
}

export const LocalPage: FunctionComponent = () => {
  const { user } = useAuthentication();
  const { userRole } = useAuthorization();
  const { countries } = useData();
  const { getApi, deleteApi, putApi, postApi } = useApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ifApplicableDisclosure = useDisclosure();
  const [statusCode, setStatusCode] = useState<number>(0);
  const [fetchFailed, setFetchFailed] = useState<boolean>(false);
  const errorToast = useErrorToast();
  const [canvasContext, setCanvasContext] =
    useState<CanvasRenderingContext2D | null>(
      document.createElement('canvas').getContext('2d'),
    );
  const theme = useTheme();
  const tdFontText =
    theme.components.Table.baseStyle.tbody.tr.td.fontSize +
    ' ' +
    theme.fonts.body;
  const publicNetwork = user?.organization?.networks.find(
    (network) => network.isPrivate === false,
  );

  useEffect(() => {
    if (canvasContext && canvasContext.font !== tdFontText) {
      canvasContext.font = tdFontText;
      setCanvasContext(canvasContext);
    }
  }, [canvasContext, tdFontText]);

  const { setCountryId, countryId, transport, category, reset } =
    useLocalFilters(
      (state) => ({
        countryId: state.countryId,
        transport: state.transportMode,
        category: state.category,
        setCountryId: state.setCountry,
        reset: state.reset,
      }),
      shallow,
    );

  useEffect(() => {
    reset();
    setCountryId(countries.data?.at(0)?.countryID ?? '');
  }, [reset, setCountryId, countries]);

  const {
    isLoading,
    data: rates,
    refetch: refetchRates,
  } = useQuery<LocalCharge[]>(
    ['local-charges', countryId, transport, category],
    async () => {
      setFetchFailed(false);
      if (countryId === undefined) return [];

      const result = await getApi(
        `rates/localcharges?countryID=${countryId}&transportMode=${TransportMode[
          transport
        ].toLowerCase()}&category=${category}&networkID=${
          publicNetwork?.networkID
        }`,
      );

      setStatusCode(result.status);
      let data = await result.json();
      if (result.ok) return data;

      setFetchFailed(true);
      return [];
    },
  );

  const {
    isLoading: isLoadingApplicableRates,
    data: ifApplicableRates,
    refetch: refetchIfApplicableRates,
  } = useQuery<IfApplicable[]>(
    ['ifApplicable', countryId, transport, category],
    async () => {
      if (countryId === undefined) return [];

      const result = await getApi(
        `rates/ifapplicables?countryID=${countryId}&transportMode=${TransportMode[
          transport
        ].toLowerCase()}&category=${category}&networkID=${
          publicNetwork?.networkID
        }`,
      );

      if (result.ok) return result.json();

      return [];
    },
  );

  const baseColumns = useMemo<Column<LocalCharge>[]>(
    () => [
      {
        Header: 'Code',
        accessor: (rate) => rate.chargeCode.code,
        Filter: (columns: FilterProps<LocalCharge>) => (
          <SelectColumnFilter column={columns.column} />
        ),
      },
      {
        Header: 'Name',
        accessor: (rate) => rate.chargeCode.name,
        disableFilters: true,
        width: Math.max(
          ...(rates?.map((rate) =>
            getTextWidth({
              canvasContext: canvasContext,
              text: rate.chargeCode.name,
              minLength: 150,
            }),
          ) ?? [150]),
        ),
      },
      {
        Header: transport === TransportMode.Air ? 'Airport' : 'Port',
        accessor: (rate) => rate.port?.unloCode,
        Filter: (columns: FilterProps<LocalCharge>) => (
          <SelectColumnFilter column={columns.column} />
        ),
      },
      {
        Header: 'Currency',
        accessor: (rate) => rate.currency.code,
        disableFilters: true,
      },
      {
        Header: 'Flat',
        accessor: (rate) => rate.flat?.toFixed(2),
        disableFilters: true,
      },
      ...conditionalHeader<LocalCharge>(
        {
          Header: 'Min',
          accessor: (rate) => rate.minimumCharge?.toFixed(2),
          disableFilters: true,
        },
        transport !== TransportMode.FCL,
      ),
    ],
    [canvasContext, rates, transport],
  );

  const validityColumns = useMemo<Column<LocalCharge>[]>(
    () => [
      {
        Header: 'Valid From',
        accessor: (rate) => toDisplayDateFormat(rate.validFrom),
        disableFilters: true,
      },
      {
        Header: 'Valid To',
        accessor: (rate) => toDisplayDateFormat(rate.validTo),
        disableFilters: true,
      },
    ],
    [],
  );

  const columns = useMemo<Column<LocalCharge>[]>(() => {
    switch (transport) {
      case TransportMode.LCL:
        return [
          ...baseColumns,
          {
            Header: 'Rate per CBM',
            accessor: (rate) => rate.ratePerCbm?.toFixed(2),
            disableFilters: true,
          },
          {
            Header: 'Rate per 1000 Kg',
            accessor: (rate) => rate.ratePerKg?.toFixed(2),
            disableFilters: true,
          },
          ...validityColumns,
        ];
      case TransportMode.FCL:
        return [
          ...baseColumns,
          {
            Header: 'Rate per 20GP',
            accessor: (rate) => rate.ratePer20FeetContainer?.toFixed(2),
            disableFilters: true,
          },
          {
            Header: 'Rate per 40GP',
            accessor: (rate) => rate.ratePer40FeetContainer?.toFixed(2),
            disableFilters: true,
          },
          {
            Header: 'Rate per 40HC',
            accessor: (rate) => rate.ratePer40HCFeetContainer?.toFixed(2),
            disableFilters: true,
          },
          ...validityColumns,
        ];
      case TransportMode.Air:
      default:
        return [
          ...baseColumns,
          {
            Header: 'Rate',
            accessor: (rate) => rate.ratePerKg?.toFixed(2),
            disableFilters: true,
          },
          ...validityColumns,
        ];
    }
  }, [baseColumns, transport, validityColumns]);

  const onCreate = useCallback(
    async (submitData: LocalChargeFormValues) => {
      const newRate: Omit<
        LocalChargeResponse,
        'id' | 'createUser' | 'createTimestamp'
      > = {
        country: countries.data?.filter(
          (country) => country.countryID === countryId,
        )[0]!,
        chargeCode: submitData.chargeCode!,
        port:
          submitData.port && submitData.port.portID !== ''
            ? submitData.port
            : null,
        currency: submitData.currency,
        flat: submitData.flat ? parseFloat(submitData.flat) : null,
        validFrom: formatChargeDate(submitData.validFrom),
        validTo: formatChargeDate(submitData.validTo),
        transportMode: transport,
        isOrigin: category === 'Export',
        isDestination: category === 'Import',
      };

      if (transport === TransportMode.FCL) {
        newRate.ratePer20FeetContainer = submitData.ratePer20FeetContainer
          ? parseFloat(submitData.ratePer20FeetContainer)
          : null;
        newRate.ratePer40FeetContainer = submitData.ratePer40FeetContainer
          ? parseFloat(submitData.ratePer40FeetContainer)
          : null;
        newRate.ratePer40HCFeetContainer = submitData.ratePer40HCFeetContainer
          ? parseFloat(submitData.ratePer40HCFeetContainer)
          : null;
      } else {
        newRate.minimumCharge = submitData.min
          ? parseFloat(submitData.min)
          : null;
        newRate.ratePerKg = submitData.ratePerKg
          ? parseFloat(submitData.ratePerKg)
          : null;
      }

      if (transport === TransportMode.LCL) {
        newRate.ratePerCbm = submitData.ratePerCbm
          ? parseFloat(submitData.ratePerCbm)
          : null;
      }

      let response: Response;
      try {
        response = await postApi('rates/localcharges', newRate);
      } catch (e) {
        errorToast({ title: errorTitle });
        return false;
      }

      if (response.ok) {
        await refetchRates();
        return true;
      }

      errorToast({
        title:
          response.status === 409 || response.status === 400
            ? await response.text()
            : getHttpStatusDescription(response.status),
      });
      return false;
    },
    [
      category,
      countryId,
      postApi,
      refetchRates,
      errorToast,
      transport,
      countries,
    ],
  );

  const onEdit = useCallback(
    async (submitData: LocalChargeFormValues, rate?: LocalCharge) => {
      if (!rate) {
        return false;
      }

      const updatedRate: LocalChargeResponse = {
        ...rate,
        chargeCode: submitData.chargeCode!,
        port:
          submitData.port && submitData.port.portID ? submitData.port : null,
        currency: submitData.currency,
        flat: submitData.flat ? parseFloat(submitData.flat) : null,
        validFrom: formatChargeDate(submitData.validFrom),
        validTo: formatChargeDate(submitData.validTo),
      };

      if (transport === TransportMode.FCL) {
        updatedRate.ratePer20FeetContainer = submitData.ratePer20FeetContainer
          ? parseFloat(submitData.ratePer20FeetContainer)
          : null;
        updatedRate.ratePer40FeetContainer = submitData.ratePer40FeetContainer
          ? parseFloat(submitData.ratePer40FeetContainer)
          : null;
        updatedRate.ratePer40HCFeetContainer =
          submitData.ratePer40HCFeetContainer
            ? parseFloat(submitData.ratePer40HCFeetContainer)
            : null;
      } else {
        updatedRate.minimumCharge = submitData.min
          ? parseFloat(submitData.min)
          : null;
        updatedRate.ratePerKg = submitData.ratePerKg
          ? parseFloat(submitData.ratePerKg)
          : null;
      }

      if (transport === TransportMode.LCL) {
        updatedRate.ratePerCbm = submitData.ratePerCbm
          ? parseFloat(submitData.ratePerCbm)
          : null;
      }

      let response: Response;
      try {
        response = await putApi(
          `rates/localcharges/${updatedRate.id}`,
          updatedRate,
        );
      } catch (e) {
        errorToast({ title: errorTitle });
        return false;
      }

      if (response.ok) {
        await refetchRates();
        return true;
      }

      errorToast({
        title:
          response.status === 409 || response.status === 400
            ? await response.text()
            : getHttpStatusDescription(response.status),
      });
      return false;
    },
    [putApi, refetchRates, errorToast, transport],
  );

  const onDelete = useCallback(
    async (id: string) => {
      let response;
      try {
        response = await deleteApi(`rates/localcharges/${id}`);
      } catch (e) {
        errorToast({ title: errorTitle });
        return false;
      }

      if (response.ok) {
        await refetchRates();
        return true;
      }

      errorToast({ title: getHttpStatusDescription(response.status) });
      return false;
    },
    [deleteApi, refetchRates, errorToast],
  );

  const onCreateIfApplicable = async (ifApplicable: IfApplicable) => {
    let response = null;
    try {
      response = await postApi('rates/ifapplicables', ifApplicable);
    } catch (e) {
      errorToast({ title: errorTitle });
      return false;
    }

    if (response.ok) {
      refetchIfApplicableRates();
      return true;
    }
    errorToast({
      title:
        response.status === 409 || response.status === 400
          ? await response.text()
          : getHttpStatusDescription(response.status),
    });
    return false;
  };

  const onDeleteIfApplicable = async (rateId: string) => {
    let response = null;
    try {
      response = await deleteApi(`rates/ifapplicables?rateId=${rateId}`);
    } catch (e) {
      errorToast({ title: errorTitle });
      return false;
    }

    if (response.ok) {
      await refetchIfApplicableRates();
      return true;
    }
    errorToast({ title: getHttpStatusDescription(response.status) });
    return false;
  };

  const onEditIfApplicable = async (submitData: IfApplicable) => {
    let response = null;
    try {
      response = await putApi(
        `rates/ifapplicables?rateId=${submitData.infoId}`,
        submitData,
      );
    } catch (e) {
      errorToast({ title: errorTitle });
      return false;
    }

    if (response.ok) {
      refetchIfApplicableRates();
      return true;
    }
    errorToast({
      title:
        response.status === 409 || response.status === 400
          ? await response.text()
          : getHttpStatusDescription(response.status),
    });
    return false;
  };

  const canMutateRates = checkCanMutateRates(userRole, user, [countryId]);

  return (
    <>
      <HeadingCard heading="Local Charges" />
      <Card>
        <LocalChargeFilter />
      </Card>
      <MainCard minHeight="25rem" flex="0 1 auto">
        <UpsertRateModal<LocalCharge, LocalChargeFormValues>
          rateType={RateType.Local}
          isOpen={isOpen}
          onClose={onClose}
          onSave={onCreate}
          isCreate={true}
          onDeleteOpen={() => {}}
          createRateHeaderTitle="Create Local Charge"
        />
        <HStack justifyContent="space-between">
          <Heading as="h2" variant="h2">
            Standard
          </Heading>
          {canMutateRates && (
            <Button
              leftIcon={<PlusIcon w="4" h="4" />}
              size="xs"
              colorScheme="secondary"
              onClick={() => onOpen()}
              isDisabled={
                countries.data === undefined || countries.data.length === 0
              }
            >
              Add Local Charge
            </Button>
          )}
        </HStack>
        <RateTable<LocalCharge, LocalChargeFormValues>
          rateType={RateType.Local}
          data={rates}
          columns={columns}
          isLoading={isLoading}
          fetchFailed={fetchFailed}
          enableFilters={true}
          statusCode={statusCode}
          onEdit={onEdit}
          onDelete={onDelete}
          showActionColumn={canMutateRates}
          updateRateModalHeaderTitle="Edit Local Charge"
        />
      </MainCard>
      <MainCard minHeight="25rem" flex="0 1 auto">
        <UpsertIfApplicableRate
          isOpen={ifApplicableDisclosure.isOpen}
          isCreate={true}
          onSave={onCreateIfApplicable}
          onClose={ifApplicableDisclosure.onClose}
          onDeleteOpen={() => {}}
        />
        <HStack justifyContent="space-between">
          <Heading as="h2" variant="h2">
            If Applicable
          </Heading>
          {canMutateRates && (
            <Button
              leftIcon={<PlusIcon w="4" h="4" />}
              size="xs"
              colorScheme="secondary"
              onClick={() => ifApplicableDisclosure.onOpen()}
              isDisabled={
                countries.data === undefined || countries.data.length === 0
              }
            >
              Add If Applicable Charge
            </Button>
          )}
        </HStack>
        <IfApplicableTable
          ifApplicables={ifApplicableRates ?? []}
          renderType={transport}
          isLoading={isLoadingApplicableRates}
          onDelete={onDeleteIfApplicable}
          onEdit={onEditIfApplicable}
          showActionColumn={canMutateRates}
          hasFilters={true}
          canvasContext={canvasContext}
        />
      </MainCard>
    </>
  );
};

import { User, UserRole } from '../../../types';

export const checkCanMutateRates = (
  userRole: UserRole,
  user: User | null,
  countriesToCheck: (string | undefined)[],
) => {
  if (!user || userRole === UserRole.STANDARD_USER) return false;
  return (
    (userRole === UserRole.ORGANIZATION_MANAGER &&
      user.organization?.assignedCountries.some((country) =>
        countriesToCheck.some((id) => id != null && id === country.countryID),
      )) ||
    user.assignedCountries.some((country) =>
      countriesToCheck.some((id) => id != null && id === country.countryID),
    )
  );
};

import { useDisclosure } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BackModal } from '../../../components/Modals/BackModal';
import { CancelModal } from '../../../components/Modals/CancelModal';
import { useTariffCreateStore } from '../../../hooks/useTariffCreateStore';
import { useTruckingFilters } from '../../../hooks/useTruckingFilters';
import {
  CreateTariffMetaFormValues,
  CreateTariffMetaValues,
} from '../../../types/forms/CreateTariffMetaValues';
import { findPortsByIdList } from '../../../utils/model/findPortByID';
import { routes } from '../../../utils/routes';
import { TariffMetaEditForm } from '../TariffPage/components/TariffMetaEditForm';
import { useTariffEditOptions } from '../TariffPage/hooks/useTariffEditOptions';
import { TariffCreateHeading } from './components/TariffCreateHeading';

export const TariffCreatePage: FunctionComponent = () => {
  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const {
    isOpen: isBackOpen,
    onOpen: onBackOpen,
    onClose: onBackClose,
  } = useDisclosure();

  const navigate = useNavigate();

  const { countryId, transportTypes, directionTypes } = useTruckingFilters();

  const { country } = useTariffCreateStore();

  const tariffEditOptions = useTariffEditOptions(
    country?.countryID || countryId || '',
    transportTypes[0],
  );

  if (
    !tariffEditOptions.selectedCountry ||
    !tariffEditOptions.selectedCountry.countryID
  ) {
    navigate(routes.charges.trucking);
  }

  const {
    setMetaForm,
    setCountry,
    setSelectedPorts,
    setSelectedAirports,
    metaForm,
  } = useTariffCreateStore();

  const initialFormValues: Partial<CreateTariffMetaFormValues> = {
    name: metaForm?.name || '',
    transportType: metaForm?.transportType || transportTypes[0],
    method: metaForm?.method || undefined,
    directions: metaForm?.directions || directionTypes,
    currency: metaForm?.currency || undefined,
    percentFuel: metaForm?.percentFuel || undefined,
    calculator: metaForm?.calculator || undefined,
    chargeableWeight: metaForm?.chargeableWeight || undefined,
    chargeableRatio: metaForm?.chargeableRatio || undefined,
    assignedPorts: [],
    assignedAirports: [],
    validFrom: metaForm?.validFrom || undefined,
    validTo: metaForm?.validTo || undefined,
  };

  const metaEditForm = useForm<CreateTariffMetaValues>({
    defaultValues: {
      ...initialFormValues,
      assignedAirports: initialFormValues.assignedAirports?.map(
        (port) => port.value as string,
      ),
      assignedPorts: initialFormValues.assignedPorts?.map(
        (port) => port.value as string,
      ),
    },
  });

  const headingValue = useWatch({
    control: metaEditForm.control,
    name: 'name',
  });

  const handleCancelEdit = () => {
    navigate(routes.charges.trucking);
  };

  const onSubmit = async (items: CreateTariffMetaValues) => {
    setMetaForm(items);
    setCountry(tariffEditOptions.selectedCountry);
    setSelectedPorts(
      findPortsByIdList(
        tariffEditOptions.allports,
        metaEditForm.getValues('assignedPorts'),
      ),
    );
    setSelectedAirports(
      findPortsByIdList(
        tariffEditOptions.allports,
        metaEditForm.getValues('assignedAirports'),
      ),
    );
    navigate(routes.charges.tariffCreateRates);
  };

  return (
    <>
      <TariffCreateHeading
        tariffTitle={headingValue}
        onNext={metaEditForm.handleSubmit((items) => onSubmit(items))}
        onClose={onCancelOpen}
        onBack={onBackOpen}
      />
      <TariffMetaEditForm
        airports={tariffEditOptions.formattedPorts}
        ports={tariffEditOptions.formattedPorts}
        currencies={tariffEditOptions.formattedCurrencies}
        transportType={transportTypes[0]}
        selectedCountry={tariffEditOptions.selectedCountry}
        formInstance={metaEditForm}
        initialFormValues={initialFormValues}
      />
      <CancelModal
        isOpen={isCancelOpen}
        onCancel={onCancelClose}
        onAction={handleCancelEdit}
      />
      <BackModal
        isOpen={isBackOpen}
        onCancel={onBackClose}
        onAction={handleCancelEdit}
      />
    </>
  );
};

import {
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { StringOrNumber } from '@chakra-ui/utils';
import { FunctionComponent } from 'react';
import { DeselectableSelectInput } from '../../../../components/Input/DeselectableSelectInput';
import { useTruckingFilters } from '../../../../hooks/useTruckingFilters';
import { useData } from '../../../../providers/DataProvider';
import { TransportMode } from '../../../../types';
import { ApiTransportDirection } from '../../../../types/TransportDirection';

export const TruckingChargeFilter: FunctionComponent = () => {
  const onMobile = useBreakpointValue({ base: true, xl: false });
  const { countries } = useData();
  const {
    countryId,
    setCountryId,
    transportTypes,
    setTransportTypes,
    directionTypes,
    setDirectionTypes,
  } = useTruckingFilters();

  const initialTransportTypes = transportTypes.map((value) => value.toString());
  const initialDirectionTypes = directionTypes.map((value) => value.toString());

  const getFormattedCheckboxValues = (values: StringOrNumber[]) =>
    values.map((value) => parseInt(value.toString(), 10));

  const handleTransportChange = (values: StringOrNumber[]) => {
    setTransportTypes(getFormattedCheckboxValues(values));
  };

  const handleDirectionChange = (values: StringOrNumber[]) => {
    setDirectionTypes(getFormattedCheckboxValues(values));
  };

  return (
    <HStack gap="8">
      <DeselectableSelectInput
        label="Country"
        accessor="originCountry"
        currentValue={countryId}
        onSelectionChange={(selected) => {
          setCountryId(selected?.value);
        }}
        width={onMobile ? '100%' : '13rem'}
        options={
          countries.data?.map((country) => ({
            label: country.name,
            value: country.countryID,
          })) ?? []
        }
        noOptionsMessage="You don't have any assigned countries"
      />
      <FormControl>
        <FormLabel htmlFor="transport">Transport Type</FormLabel>
        <CheckboxGroup
          colorScheme="blue"
          defaultValue={initialTransportTypes}
          onChange={(val) => handleTransportChange(val)}
        >
          <Stack spacing={[1, 5]} direction={['column', 'row']}>
            <Checkbox
              value={TransportMode.Air.toString()}
              key={TransportMode.Air.toString()}
            >
              <Text fontSize="sm">Air</Text>
            </Checkbox>
            <Checkbox
              value={TransportMode.LCL.toString()}
              key={TransportMode.LCL.toString()}
            >
              <Text fontSize="sm">LCL</Text>
            </Checkbox>
          </Stack>
        </CheckboxGroup>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="ded">Direction</FormLabel>
        <CheckboxGroup
          colorScheme="blue"
          onChange={(val) => handleDirectionChange(val)}
          defaultValue={initialDirectionTypes}
        >
          <Stack spacing={[1, 5]} direction={['column', 'row']}>
            <Checkbox
              value={ApiTransportDirection.Import.toString()}
              key={ApiTransportDirection.Import.toString()}
              fontSize="sm"
            >
              <Text fontSize="sm">Import</Text>
            </Checkbox>
            <Checkbox
              value={ApiTransportDirection.Export.toString()}
              key={ApiTransportDirection.Export.toString()}
            >
              <Text fontSize="sm">Export</Text>
            </Checkbox>
          </Stack>
        </CheckboxGroup>
      </FormControl>
    </HStack>
  );
};

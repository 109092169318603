import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import {
  Button,
  HStack,
  ModalFooter,
  Text,
  Textarea,
  VStack,
  CloseButton,
} from '@chakra-ui/react';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import { useApi } from '../../providers/ApiProvider';

interface QuoteSatisfactionModalProps {
  isOpen: boolean;
  handleModalClose: () => void;
  quoteId: string;
  wasSubmitted: boolean;
  setWasSubmitted: (value: boolean) => void;
}

export const QuoteSatisfactionModal: FunctionComponent<
  QuoteSatisfactionModalProps
> = ({ isOpen, handleModalClose, quoteId, wasSubmitted, setWasSubmitted }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isQuoteCompetitive, setIsQuoteCompetitive] = useState<boolean | null>(
    null,
  );
  const [comment, setComment] = useState<string>('');
  const [showWarningText, setWarningTextDisplay] = useState(false);
  const [showRequiredDescriptionText, setRequiredDescriptionText] =
    useState(false);
  const descriptionArea = useRef<HTMLTextAreaElement>(null);
  const { postApi } = useApi();
  const successToast = useSuccessToast({
    title: 'Feedback submitted successfully',
    description: 'Thank you for your feedback',
    duration: 3000,
  });

  const handleRatingChange = (value: boolean) => {
    setRequiredDescriptionText(false);
    if (isQuoteCompetitive === true && value) {
      setIsQuoteCompetitive(null);
    } else if (isQuoteCompetitive === false && !value) {
      setIsQuoteCompetitive(null);
    } else {
      setIsQuoteCompetitive(value);
      if (!value) {
        descriptionArea?.current?.focus();
      }
    }
  };
  const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setComment(event.target.value);

  const handleSubmit = async () => {
    if (wasSubmitted) {
      return;
    }
    if (isQuoteCompetitive === null) {
      setWarningTextDisplay(true);
      setRequiredDescriptionText(false);
      return;
    } else {
      setWarningTextDisplay(false);
    }

    if (!isQuoteCompetitive && comment.length === 0) {
      setRequiredDescriptionText(true);
      descriptionArea?.current?.focus();
      return;
    } else {
      setRequiredDescriptionText(false);
    }

    setIsLoading(true);

    try {
      const response = await postApi('quotes/rating', {
        quoteId,
        isQuoteCompetitive,
        comment,
      });

      if (response.ok) {
        handleModalClose();
        setWasSubmitted(true);
        successToast();
      }
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    } finally {
      setIsLoading(false);
    }
  };

  const buttonText = isLoading ? 'Sending Feedback...' : 'Send Feedback';

  return (
    <>
      <VStack justifyContent="center">
        <Modal
          isOpen={isOpen}
          onClose={handleModalClose}
          closeOnOverlayClick={false}
          closeOnEsc={false}
        >
          <ModalOverlay />
          <ModalContent
            minWidth={{ base: '20rem', md: '37.5rem' }}
            bg="grey.800"
          >
            <ModalHeader
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderBottom="4px solid"
              borderColor="grey.900"
              word-wrap="break-word"
              textAlign="center"
            >
              <HStack>
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: '20px' }}
                >
                  reviews
                </span>
                <h2 className="chakra-heading">Rate This Quotation</h2>
              </HStack>
              <CloseButton
                position="absolute"
                top="12px"
                right="12px"
                onClick={handleModalClose}
              />
            </ModalHeader>
            <ModalBody
              paddingInline="28px"
              paddingBottom="32px"
              paddingTop="32px"
              paddingLeft="56px"
              paddingRight="56px"
            >
              <Text mb={8} fontSize="sm">
                Is this quotation market competitive? <br /> Help us improve
                quotation results by providing feedback.
              </Text>
              <Text
                as="label"
                justifyContent="flex-start"
                color="gray.400"
                fontSize="xs"
              >
                Rating
              </Text>
              <HStack mt={5} mb={6} spacing={3} justifyContent="start">
                <Button
                  variant={isQuoteCompetitive === true ? 'solid' : 'outline'}
                  onClick={() => handleRatingChange(true)}
                  colorScheme="blue"
                  leftIcon={
                    <span className="material-symbols-outlined">thumb_up</span>
                  }
                  style={{
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    boxShadow: 'none',
                  }}
                >
                  Competitive
                </Button>
                <Button
                  variant={isQuoteCompetitive === false ? 'solid' : 'outline'}
                  onClick={() => handleRatingChange(false)}
                  mr={3}
                  colorScheme="blue"
                  leftIcon={
                    <span className="material-symbols-outlined">
                      thumb_down
                    </span>
                  }
                  style={{
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    boxShadow: 'none',
                  }}
                >
                  Not Competitive
                </Button>
              </HStack>
              <Text
                as="label"
                justifyContent="flex-start"
                color="gray.400"
                fontSize="xs"
              >
                Comment
              </Text>
              <Textarea
                ref={descriptionArea}
                paddingInline="6"
                borderColor="grey.700"
                fontSize="sm"
                minHeight="180px"
                mt="3"
                focusBorderColor={
                  showRequiredDescriptionText ? 'red.500' : 'blue.500'
                }
                value={comment}
                _hover={{}}
                onChange={handleCommentChange}
                placeholder="Thank you for rating! If you have any specific charges that you feel are not market competitive, please share your thoughts in the comments. Your feedback helps us improve!"
              />
            </ModalBody>
            <ModalFooter display="flex" justifyContent="center">
              <Button
                isLoading={isLoading}
                onClick={handleSubmit}
                colorScheme="blue"
                loadingText="Sending Feedback..."
              >
                {buttonText}
              </Button>
            </ModalFooter>
            <VStack paddingY={'1rem'}>
              {showWarningText && (
                <Text color="red" fontSize={'smaller'} textAlign={'center'}>
                  * Please select either ‘Competitive’ or ‘Not competitive’
                  before submitting your feedback.
                </Text>
              )}
              {showRequiredDescriptionText && (
                <Text color="red" fontSize={'smaller'} textAlign={'center'}>
                  * Please leave a comment explaining why the quote is not
                  competitive.
                </Text>
              )}
            </VStack>
          </ModalContent>
        </Modal>
      </VStack>
    </>
  );
};

import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import { FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import { theme } from '../../theme';

export interface CustomRemarksContainerProps {
  markdown: string;
}

export const MarkdownContainer: FunctionComponent<
  CustomRemarksContainerProps
> = ({ markdown }) => (
  <ReactMarkdown components={ChakraUIRenderer(theme, true)} skipHtml>
    {markdown}
  </ReactMarkdown>
);

import { ReactElement } from 'react';
import { RateType } from '../../types';
import { BoatIcon } from './BoatIcon';
import { LCLIcon } from './LCLIcon';
import { LocalIcon } from './LocalIcon';
import { PlaneIcon } from './PlaneIcon';
import { TruckIcon } from './TruckIcon';

export const rateTypeIcons: Record<RateType, ReactElement> = {
  [RateType.Local]: <LocalIcon w="6" h="6" />,
  [RateType.Trucking]: <TruckIcon w="6" h="6" />,
  [RateType.Air]: <PlaneIcon w="6" h="6" />,
  [RateType.SeaFcl]: <BoatIcon w="6" h="6" />,
  [RateType.SeaLcl]: <LCLIcon w="6" h="6" />,
};

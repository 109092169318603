import { useState } from 'react';
import { useQuery } from 'react-query';
import { useApi } from '../../../../providers/ApiProvider';
import { useAuthentication } from '../../../../providers/AuthenticationProvider';
import { TariffTruckingDto } from '../../../../types/api/TariffTruckingDto';
import { API_ROUTES } from '../../../../utils/apiRoutes';

export const useSingleTariffApi = (tariffId: string | undefined) => {
  const [fetchFailed, setFetchFailed] = useState<boolean>(false);
  const [statusCode, setStatusCode] = useState<number>(0);
  const { getApi } = useApi();
  const { user } = useAuthentication();
  const publicNetwork = user?.organization?.networks.find(
    (network) => network.isPrivate === false,
  );

  const {
    isLoading,
    data: rate,
    refetch,
  } = useQuery<TariffTruckingDto | null>(
    ['trucking-charges', tariffId],
    async () => {
      if (!tariffId) return null;
      setFetchFailed(false);

      const result = await getApi(
        API_ROUTES.tariffsTruckingSingle(tariffId, {
          networkID: publicNetwork?.networkID,
        }),
      );

      setStatusCode(result.status);
      if (result.ok) {
        return (await result.json()) as TariffTruckingDto;
      }
      setFetchFailed(true);

      return null;
    },
    { refetchOnWindowFocus: false },
  );

  return { isLoading, rate, fetchFailed, statusCode, refetch };
};

import { CheckIcon, ChevronRightIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import type { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Column, FilterProps } from 'react-table';
import { DataTable } from '../../../components/DataTable';
import { ResetAllColumnsFilter } from '../../../components/DataTable/ResetAllColumnsFilter';
import { SelectColumnFilter } from '../../../components/DataTable/SelectColumnFilter';
import { TableActionButtonProps } from '../../../theme/components';
import type { Organization } from '../../../types';
import { routes } from '../../../utils/routes';

interface OrganizationsTableProps {
  data?: Organization[];
  isLoading: boolean;
  fetchFailed: boolean;
  statusCode: number;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  showDeactivatedOrgs: boolean;
  setShowDeactivatedOrgs: (show: boolean) => void;
}

export const OrganizationsTable: FunctionComponent<OrganizationsTableProps> = ({
  data,
  isLoading,
  fetchFailed,
  statusCode,
  searchQuery,
  setSearchQuery,
  showDeactivatedOrgs,
  setShowDeactivatedOrgs,
}) => {
  const columns = useMemo<Column<Organization>[]>(
    () => [
      {
        Header: 'Name',
        accessor: (org) => org.name,
      },
      {
        Header: 'Location',
        accessor: (org) => org.mainCountry?.name ?? '-',
        Filter: (cols: FilterProps<Organization>) => (
          <SelectColumnFilter column={cols.column} />
        ),
      },
      {
        Header: 'No. of Users',
        accessor: (org) => org.userCount,
        disableFilters: true,
      },
      {
        Header: 'Active',
        accessor: (org) => (org.isActive ? <CheckIcon /> : null),
        disableFilters: true,
      },
      {
        id: 'action',
        accessor: (org) => (
          <Flex w="100%" justifyContent="flex-end">
            <Button
              as={RouterLink}
              to={routes.organizations.details(org.organizationID)}
              state={{ hasBackButton: true }}
              {...TableActionButtonProps}
              rightIcon={<ChevronRightIcon w="4" h="4" />}
            >
              Details
            </Button>
          </Flex>
        ),
        Filter: ResetAllColumnsFilter,
      },
    ],
    [],
  );

  return (
    <>
      <Stack spacing="2">
        <FormLabel>Search for organization</FormLabel>
        <HStack>
          <InputGroup width={{ base: 'full', lg: '40%' }} mr="4">
            <Input
              placeholder="Search for organization..."
              defaultValue={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <InputRightElement
              pointerEvents="none"
              children={<SearchIcon color="blue.500" />}
            />
          </InputGroup>
          <Checkbox
            isChecked={showDeactivatedOrgs}
            onChange={(event) => setShowDeactivatedOrgs(event.target.checked)}
            size="sm"
          >
            Show deactivated Organizations
          </Checkbox>
        </HStack>
      </Stack>
      <Divider borderColor="grey.900" borderBottomWidth="2px" opacity="unset" />
      <Heading as="h2" variant="h2">
        Organizations ({data?.length ?? 0})
      </Heading>
      <DataTable<Organization>
        data={data}
        columns={columns}
        isLoading={isLoading}
        fetchFailed={fetchFailed}
        statusCode={statusCode}
        variant="sticky"
        enableFilters={true}
      />
    </>
  );
};

import { Skeleton, Stack } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';
import { FormInput, SelectFormInput } from '../../components/Input';
import { ErrorResult } from '../../components/Result';
import { useAuthorization } from '../../providers/AuthorizationProvider';
import { useData } from '../../providers/DataProvider';
import { Organization, UserRole } from '../../types';
import { OrganizationForm } from '.';

interface IOrganizationInformationFieldsProps {
  isLoading: boolean;
  isSubmitting: boolean;
  organization?: Organization;
  register: UseFormRegister<OrganizationForm>;
  errors: FieldErrors<OrganizationForm>;
  control: Control<OrganizationForm>;
  statusCode: number;
}

export const OrganizationInformationFields: FunctionComponent<
  IOrganizationInformationFieldsProps
> = ({
  isLoading,
  organization,
  register,
  errors,
  isSubmitting,
  control,
  statusCode,
}) => {
  const { countries } = useData();
  const { hasRole } = useAuthorization();

  if (isLoading) {
    return <Skeleton height="200px" />;
  }

  if (organization == null || countries.data == null) {
    return <ErrorResult statusCode={statusCode} />;
  }

  return (
    <Stack spacing="5">
      <FormInput
        accessor="name"
        label="Name"
        register={register}
        errors={errors}
        defaultValue={organization.name}
        isDisabled={isSubmitting}
        isRequired={true}
        registerOptions={{
          maxLength: { value: 50, message: 'Maximum of 50 characters' },
        }}
      />
      <FormInput
        accessor="addressText"
        label="Address"
        register={register}
        errors={errors}
        defaultValue={organization.addressText}
        isDisabled={isSubmitting}
        isRequired={true}
        registerOptions={{
          maxLength: { value: 100, message: 'Maximum of 100 characters' },
        }}
      />
      <SelectFormInput
        accessor="country"
        label="Country"
        controllerDefaultValue={organization.mainCountry?.countryID}
        defaultValue={{
          label: organization.mainCountry?.name,
          value: organization.mainCountry?.countryID,
        }}
        isDisabled={isSubmitting}
        isRequired={true}
        control={control}
        options={countries.data.map((country) => ({
          label: country.name,
          value: country.countryID,
        }))}
      />
      {hasRole([UserRole.GLOBAL_ADMINISTRATOR]) && (
        <FormInput
          accessor="qwykOrganizationId"
          label="QWYK Hub ID"
          register={register}
          errors={errors}
          defaultValue={organization.qwykOrganizationId}
          isDisabled={isSubmitting}
          isRequired={false}
          registerOptions={{
            maxLength: { value: 50, message: 'Maximum of 50 characters' },
          }}
        />
      )}
    </Stack>
  );
};

import { type Currency } from '../../types';
import { Data } from '../../types/Data';
import { MultiselectOption } from '../../types/MultiSelectOption';

export const formatCurrenciesToOptions = (
  currencies: Data<Currency[]>,
): MultiselectOption[] =>
  currencies.data
    ? currencies.data.map((currency) => ({
        label: currency.code,
        value: currency.code,
      }))
    : [];

import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import type { Control } from 'react-hook-form';
import type { Props } from 'react-select';
import type { FormInputProps } from './FormInput';
import { MultiSelect } from './MultiSelect';

export interface SelectInputOption {
  label: string;
  value: string;
}

interface MultiSelectFormInputProps
  extends Omit<FormInputProps, 'register' | 'errors'>,
    Props {
  control: Control<any>;
  controllerDefaultValue?: any;
  isRequired?: boolean;
  forwardRef?: any;
}

export const MultiSelectFormInput: FunctionComponent<
  MultiSelectFormInputProps
> = ({
  label,
  accessor,
  isRequired,
  isDisabled,
  control,
  controllerDefaultValue,
  forwardRef,
  ...rest
}: MultiSelectFormInputProps) => (
  <Controller
    name={accessor}
    control={control}
    defaultValue={controllerDefaultValue}
    rules={{ required: isRequired && 'This field is required' }}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <FormControl isInvalid={error ? true : false} isRequired={isRequired}>
        <FormLabel htmlFor={accessor}>{label}</FormLabel>
        <MultiSelect
          isDisabled={isDisabled}
          onChange={(values) =>
            onChange((values as SelectInputOption[]).map((c) => c.value))
          }
          error={error ? true : false}
          currentValue={value?.length > 0 ? true : false}
          aria-label={accessor}
          forwardRef={forwardRef}
          {...rest}
        />
        <FormErrorMessage>{error && error.message}</FormErrorMessage>
      </FormControl>
    )}
  />
);

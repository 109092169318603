import { BracketWeightDto } from '../../../../types/api/BracketWeightDto';
import { EMPTY_BRACKET } from '../constants/emptyBracket';

// This method works on an assumption that bracketList will come sorted from BE
export const getPrefilledBrackets = (
  bracketList: BracketWeightDto[],
  columnCount: number,
  savedForm?: Record<string, unknown>,
): BracketWeightDto[] => {
  const brackets = [];
  for (let i = 0; i < columnCount; i += 1) {
    const bracket = bracketList[i];
    if (savedForm) {
      brackets.push({ ...EMPTY_BRACKET, order: i, isMinValue: i === 0 });
    } else if (bracket) {
      brackets.push({ ...bracket, order: i, isMinValue: i === 0 });
    } else {
      brackets.push({ ...EMPTY_BRACKET, order: i, isMinValue: i === 0 });
    }
  }
  return brackets;
};

import { Flex, Text, TableProps } from '@chakra-ui/react';
import { NumberFormInput } from '../../../../components/Input';

interface TariffWeightInputCellProps extends TableProps {
  accessor: string;
  control: any;
  defaultValue: any;
  label: string;
  isRequired?: boolean;
}

const MAX_WEIGHT = 9999;
const WEIGHT_PRECISION = 0;

export const TariffWeightInputCell = ({
  accessor,
  control,
  defaultValue,
  label,
  isRequired = true,
}: TariffWeightInputCellProps) => (
  <Flex flexDirection="column">
    <Text fontSize="xs" paddingBottom="1" textTransform="uppercase">
      {label}
    </Text>
    <NumberFormInput
      accessor={accessor}
      height="30px"
      fontSize="sm"
      width="96px"
      textAlign="center"
      borderRadius="12px"
      inputFieldColor="grey.100"
      control={control}
      isRequired={isRequired}
      defaultValue={defaultValue}
      max={MAX_WEIGHT}
      precision={WEIGHT_PRECISION}
      aria-required="true"
      hideLabel={true}
      registerOptions={{
        maxLength: { value: 50, message: 'Maximum of 50 characters' },
      }}
      rightElement={<Text height="26px">KG</Text>}
      paddingInlineEnd="9"
    />
  </Flex>
);

import { useTheme } from '@chakra-ui/react';
import { StylesConfig } from 'react-select';

export const useReactSelectStylings = (
  width: string,
  hasValue: boolean,
  isDisabled: boolean = false,
  menuListMaxHeight: string = '20rem',
) => {
  const theme = useTheme();

  const styles: StylesConfig = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? theme.colors.blue[500] : '',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: theme.colors.grey[300],
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: theme.colors.grey[500],
      color: theme.colors.grey[100],
      fontSize: theme.fontSizes.xs,
      fontWeight: 500,
      maxHeight: menuListMaxHeight,
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '8px',
      boxShadow: `0px 0px 0px 1px ${
        state.isFocused ? theme.colors.blue[500] : theme.colors.grey[500]
      }`,
      borderColor: state.isFocused
        ? theme.colors.blue[500]
        : theme.colors.grey[900],
      '&:hover': {
        borderColor: state.isFocused
          ? theme.colors.blue[500]
          : theme.colors.grey[400],
      },
      fontSize: theme.fontSizes.xs,
      fontWeight: 500,
      backgroundColor: isDisabled
        ? theme.colors.grey[400]
        : theme.colors.grey[900],
      color: theme.colors.grey[100],
      width,
      textIndent: hasValue ? '25px' : '0',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: theme.colors.grey[100],
      position: 'absolute',
      left: '6px',
      background: theme.colors.grey[500],
      borderRadius: '2px',
      padding: '2px',
      '&:hover': {
        color: theme.colors.blue[700],
        cursor: 'pointer',
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme.colors.blue[500],
      '&:hover': {
        color: theme.colors.blue[500],
      },
    }),
    input: (provided) => ({
      ...provided,
      color: theme.colors.grey[100],
      paddingLeft: hasValue ? '25px' : '0',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.colors.grey[100],
    }),
  };

  return styles;
};

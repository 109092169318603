import { MethodTariff } from '../../../../types/MethodTariff';
import { ApiTransportDirection } from '../../../../types/TransportDirection';
import { TariffTruckingDto } from '../../../../types/api/TariffTruckingDto';
import { CreateTariffMetaFormValues } from '../../../../types/forms/CreateTariffMetaValues';
import { formatAirportsToOptions } from '../../../../utils/model/formatAirportsToOptions';
import { formatPortsToOptions } from '../../../../utils/model/formatPortsToOptions';
import { normalizeEnumValue } from '../../../../utils/model/normalizeEnumValue';

export const getInitialMetaFormValues = (
  rate: TariffTruckingDto,
): Partial<CreateTariffMetaFormValues> => {
  const method = normalizeEnumValue(MethodTariff, rate.method[0].toString());

  return {
    name: rate.name,
    transportType: rate.transportMode,
    method: method,
    directions: rate.direction.map(
      (direction) => ApiTransportDirection[direction],
    ) as unknown as number[],
    currency: rate.currency.code || undefined,
    percentFuel: rate.percentFuel || undefined,
    calculator: rate.calculator || undefined,
    chargeableWeight: rate.chargeableWeightRatio || undefined,
    chargeableRatio: 1,
    assignedPorts: formatPortsToOptions(rate.assignedPorts, rate.country),
    assignedAirports: formatAirportsToOptions(rate.assignedPorts, rate.country),
    validFrom: rate.validFrom ? new Date(rate.validFrom) : undefined,
    validTo: rate.validTo ? new Date(rate.validTo) : undefined,
  };
};

import type { TableProps } from '@chakra-ui/react';
import { NumberFormInput } from '../../../../components/Input';

interface TariffInputCellProps extends TableProps {
  accessor: string;
  control: any;
  defaultValue: any;
  isRequired?: boolean;
}

const MAX_RATE = 9999;
const MAX_RATE_PRECISION = 2;

export const TariffInputCell = ({
  accessor,
  control,
  defaultValue,
  isRequired = true,
}: TariffInputCellProps) => (
  <NumberFormInput
    accessor={accessor}
    height="30px"
    fontSize="sm"
    width="64px"
    textAlign="center"
    borderRadius="12px"
    max={MAX_RATE}
    precision={MAX_RATE_PRECISION}
    control={control}
    isRequired={isRequired}
    defaultValue={defaultValue}
    aria-required="true"
    hideLabel={true}
    registerOptions={{
      maxLength: { value: 50, message: 'Maximum of 50 characters' },
    }}
  />
);

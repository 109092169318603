import type { TextProps } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/layout';
import type { FunctionComponent } from 'react';

interface RateCountDescriptionProps extends TextProps {
  count: number;
  type: string;
  isExpired: boolean;
}

export const RateCountDescription: FunctionComponent<
  RateCountDescriptionProps
> = ({ count, type, isExpired, ...props }) => (
  <Text
    variant="tableContent"
    fontWeight="bold"
    color={count > 0 ? 'blue.500' : 'default'}
    {...props}
  >{`${count === 0 ? 'No' : count} ${type} ${
    count === 1 ? 'charge' : 'charges'
  } ${isExpired ? 'expired' : 'expiring soon'}`}</Text>
);

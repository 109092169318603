import { FieldValues } from 'react-hook-form';

export const getRateFormValuesSorted = (
  formValues: FieldValues,
  rowCount: number,
  columnCount: number,
) => {
  // zone-${n} : first column of all values
  // header-${n} : column headers starting with 1 (as 0 is the minimal header)
  // ${n}-${n} : all columns starting with 0 after zone column
  const currentValues = Object.entries(formValues);

  const getFilteredItems = (
    items: [string, any][],
    filterString: string,
    limit: number,
  ) =>
    items.filter(
      ([key]) =>
        key.includes(filterString) &&
        parseInt(key.split(filterString)[1], 10) < limit,
    );

  const zones = getFilteredItems(currentValues, 'zone-', rowCount);
  const headers = getFilteredItems(currentValues, 'header-', columnCount);
  const cells = currentValues.filter(
    ([key]) =>
      !key.includes('header') &&
      !key.includes('zone') &&
      parseInt(key.split('-')[0], 10) < columnCount &&
      parseInt(key.split('-')[1], 10) < rowCount,
  );

  return { zones, headers, cells };
};

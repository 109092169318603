import { BracketTransportMethod } from '../../../../types/api/BracketTransportMethod';
import { BracketWeightDto } from '../../../../types/api/BracketWeightDto';
import { TariffRateRequest } from '../../../../types/api/TariffRateRequest';
import { TariffTruckingDto } from '../../../../types/api/TariffTruckingDto';
import { EMPTY_BRACKET_PLACEHOLDERS } from '../../TariffPage/constants/emptyBracketPlaceholders';
import { EMPTY_METHOD_BRACKET_PLACEHOLDER } from '../../TariffPage/constants/emptyMethodBracketPlaceholder';
import { ZIP_CODE_SEPARATOR } from '../../TariffPage/constants/tariffConstants';

export const getTariffBrackets = (
  zoneValues: [string, any][],
  headerValues: [string, any][],
  cellValues: [string, any][],
  isMPU: boolean,
  tariffTrucking?: TariffTruckingDto,
) => {
  const weightBrackets: BracketWeightDto[] = [];
  if (!tariffTrucking && isMPU) {
    weightBrackets.push({ ...EMPTY_BRACKET_PLACEHOLDERS, isMinValue: true });
  }
  const methodBrackets: BracketTransportMethod[] = [];
  let sortedTariffRates: TariffRateRequest[][] = [];

  // This works under the assumption that BE provided weightBrackets are always correctly sorted, as BE does not provide `order` properties
  headerValues.forEach((header) => {
    const [headerKey, headerVal] = header;
    const headerIndex = headerKey.split('header-')[1];

    const weightBracketMatch = tariffTrucking?.weightBrackets.length
      ? tariffTrucking?.weightBrackets[parseInt(headerIndex)]
      : null;
    weightBrackets.push({
      ...(tariffTrucking && weightBracketMatch
        ? {
            ...weightBracketMatch,
            tariffTruckingID: tariffTrucking.tariffID,
          }
        : EMPTY_BRACKET_PLACEHOLDERS),
      value: isMPU && parseInt(headerIndex) === 0 ? 0 : headerVal,
      order: parseInt(headerIndex),
      isMinValue: parseInt(headerIndex) === 0,
    });
  });

  zoneValues.forEach((zone) => {
    const [zoneKey, zoneVal] = zone;
    const zoneIndex = zoneKey.split('zone-')[1];
    if (!zoneVal) return;

    const methodBracketMatch = tariffTrucking?.methodBrackets.length
      ? tariffTrucking?.methodBrackets[parseInt(zoneIndex)]
      : null;

    const methodBracketObject: BracketTransportMethod = {
      ...EMPTY_METHOD_BRACKET_PLACEHOLDER,
      ...(tariffTrucking && methodBracketMatch
        ? {
            bracketTransportMethodID:
              methodBracketMatch.bracketTransportMethodID,
            tariffTruckingID: tariffTrucking.tariffID,
          }
        : {}),
    };

    methodBrackets.push({
      ...methodBracketObject,
      order: parseInt(zoneIndex),
      rangeFrom: zoneVal.split(ZIP_CODE_SEPARATOR)[0].trim(),
      rangeTo: zoneVal.split(ZIP_CODE_SEPARATOR)[1].trim(),
    });
  });

  const emptyRows: (TariffRateRequest | null)[][] = [];
  for (let rowCount = 0; rowCount < zoneValues.length; rowCount += 1) {
    emptyRows.push([]);
    for (let cellCount = 0; cellCount < headerValues.length; cellCount += 1) {
      emptyRows[rowCount].push(null);
    }
  }

  cellValues.forEach((cell) => {
    const [cellKey, cellVal] = cell;
    const [zoneIndex, columnIndex] = cellKey.split('-');

    emptyRows[parseInt(columnIndex)][parseInt(zoneIndex)] = {
      tariffRateID: '00000000-0000-0000-0000-000000000000',
      tariffTruckingID: '00000000-0000-0000-0000-000000000000',
      tariffTrucking: null,
      bracketWeightID: '00000000-0000-0000-0000-000000000000',
      bracketWeight: null,
      bracketTransportMethodID: '00000000-0000-0000-0000-000000000000',
      bracketTransportMethod: null,
      value: cellVal,
    };
  });
  emptyRows.forEach((row, index) => {
    sortedTariffRates.push([]);
    row.forEach((cell, cellIndex) => {
      if (cell) {
        const tariffRateMatch = tariffTrucking?.tariffRatesSorted.length
          ? tariffTrucking?.tariffRatesSorted?.[index]?.[cellIndex]
          : null;
        sortedTariffRates[index].push({
          ...cell,
          ...(tariffRateMatch && tariffTrucking
            ? {
                tariffRateID: tariffRateMatch.tariffRateID,
                bracketWeightID: tariffRateMatch.bracketWeight.bracketWeightID,
                tariffTruckingID: tariffTrucking.tariffID,
                bracketTransportMethodID:
                  tariffRateMatch.bracketTransportMethod
                    .bracketTransportMethodID,
              }
            : {}),
        });
      }
    });
  });

  return { weightBrackets, methodBrackets, sortedTariffRates };
};

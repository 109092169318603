import { Country } from './Country';
import { TransportMode } from './Rate';
import { System } from '.';

export enum PortType {
  Sea = 1,
  Air = 4,
}

export enum PortCodeType {
  IATA = 1,
  UNLO = 2,
}

export interface Port {
  portID: string;
  portType: PortType;
  name: string;
  locationCode?: string;
  geoLocation: string;
  isActive: boolean;
  country: Country;
  city?: string;
  unloCode?: string;
  iataCode?: string;
}
export interface DetailedPort {
  portID: string;
  portType: PortType;
  name: string;
  locationCode?: string;
  geoLocation: string;
  isActive: boolean;
  country: Country;
  city?: string;
  portCodes: PortCode[];
}

export interface PortCode {
  portID: string;
  portCodeID: string;
  system: System;
  code: string;
  type: PortCodeType;
}

export const isPort = (port: any) =>
  port &&
  'portID' in port &&
  'portType' in port &&
  'locationCode' in port &&
  'name' in port &&
  'geoLocation' in port &&
  'isActive' in port &&
  'country' in port &&
  'city' in port;

export const getPortTypeFromTransportMode = (rateType: TransportMode) => {
  switch (rateType) {
    case TransportMode.Air:
      return PortType.Air;
    case TransportMode.FCL:
    case TransportMode.LCL:
      return PortType.Sea;
    default:
      return 0;
  }
};

import { ArrowRightIcon } from '@chakra-ui/icons';
import { Divider, GridItem, SimpleGrid, Text } from '@chakra-ui/layout';
import { FormLabel, Grid, Stack } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import shallow from 'zustand/shallow';
import { useAirFilters } from '../../../hooks/useAirFilters';
import { useData } from '../../../providers/DataProvider';
import { NumberFormInput } from '../../Input';
import type { FieldProps } from './LocalChargeFields';

interface AirChargeFieldsProps extends FieldProps {
  getValues: (key?: string) => any;
}

export const AirChargeFields: FunctionComponent<AirChargeFieldsProps> = ({
  control,
  getValues,
}) => {
  const { countries } = useData();

  const { originCountryId, destinationCountryId } = useAirFilters(
    (state) => ({
      ports: state.ports,
      originCountryId: state.originCountryId,
      destinationCountryId: state.destinationCountryId,
    }),
    shallow,
  );

  const originCountryIdValue =
    getValues('origin.country.countryID') ?? originCountryId;
  const destinationCountryIdValue =
    getValues('destination.country.countryID') ?? destinationCountryId;
  const originIataCode = getValues('origin.iataCode');
  const destinationIataCode = getValues('destination.iataCode');

  return (
    <Stack spacing="4">
      <Stack
        direction={{ base: 'column', md: 'row' }}
        align="center"
        justifyContent="space-between"
      >
        <SimpleGrid columns={2} w={{ base: 'full', lg: '50%' }} rowGap="4">
          <FormLabel mb="0">Country</FormLabel>
          <Text fontSize="xs">
            {
              countries.data?.find(
                (country) => country.countryID === originCountryIdValue,
              )?.name
            }
          </Text>
          <FormLabel mb="0">Airport</FormLabel>
          <Text fontSize="xs">{originIataCode}</Text>
        </SimpleGrid>
        <ArrowRightIcon color="grey.400" width="32" />
        <SimpleGrid columns={2} w={{ base: 'full', lg: '50%' }} rowGap="4">
          <FormLabel mb="0">Country</FormLabel>
          <Text fontSize="xs">
            {
              countries.data?.find(
                (country) => country.countryID === destinationCountryIdValue,
              )?.name
            }
          </Text>
          <FormLabel mb="0">Airport</FormLabel>
          <Text fontSize="xs">{destinationIataCode}</Text>
        </SimpleGrid>
      </Stack>
      <Divider borderColor="grey.900" borderWidth="1px" opacity="unset" />

      <Stack>
        <Grid
          my="6"
          gap={{ base: 8, lg: 10 }}
          templateColumns={'repeat(3, 1fr)'}
        >
          <GridItem>
            <NumberFormInput
              label="Min"
              accessor="min"
              control={control}
              precision={2}
              step={1.0}
              isRequired={true}
            />
          </GridItem>
          <GridItem>
            <NumberFormInput
              label="Below 100Kg"
              accessor="below100kg"
              control={control}
              precision={2}
              step={1.0}
              isRequired={true}
            />
          </GridItem>
          <GridItem>
            <NumberFormInput
              label="Above 100Kg"
              accessor="above100kg"
              control={control}
              precision={2}
              step={1.0}
              isRequired={true}
            />
          </GridItem>
          <GridItem>
            <NumberFormInput
              label="Above 300Kg"
              accessor="above300kg"
              control={control}
              precision={2}
              step={1.0}
              isRequired={true}
            />
          </GridItem>
          <GridItem>
            <NumberFormInput
              label="Above 500Kg"
              accessor="above500kg"
              control={control}
              precision={2}
              step={1.0}
              isRequired={true}
            />
          </GridItem>
          <GridItem>
            <NumberFormInput
              label="Above 1000Kg"
              accessor="above1000kg"
              control={control}
              precision={2}
              step={1.0}
              isRequired={true}
            />
          </GridItem>
        </Grid>
      </Stack>
    </Stack>
  );
};

import { createContext, useContext, useMemo } from 'react';
import type { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import type { ChargeCode, Country, Currency, Role } from '../types';
import { Data } from '../types/Data';
import { useApi } from './ApiProvider';
import { useAuthentication } from './AuthenticationProvider';

interface DataContextProps {
  countries: Data<Country[]>;
  roles: Data<Role[]>;
  currencies: Data<Currency[]>;
  chargeCodes: Data<ChargeCode[]>;
}

const DataContext = createContext<DataContextProps>({
  countries: {
    isLoading: false,
    refetch: async () => Promise.resolve(),
  },
  roles: {
    isLoading: false,
    refetch: async () => Promise.resolve(),
  },
  currencies: {
    isLoading: false,
    refetch: async () => Promise.resolve(),
  },
  chargeCodes: {
    isLoading: false,
    refetch: async () => Promise.resolve(),
  },
});

export const useData = () => useContext(DataContext);

export const DataProvider: FunctionComponent = ({ children }) => {
  const { user } = useAuthentication();
  const publicNetwork = user?.organization?.networks.find(
    (network) => network.isPrivate === false,
  );
  const { getApi } = useApi();
  const {
    isLoading: isLoadingCountries,
    data: countries,
    refetch: refetchCountries,
  } = useQuery<Country[]>(
    'countries',
    async () => {
      const result = await getApi(
        `countries?networkID=${publicNetwork?.networkID}`,
      );
      if (result.ok) {
        return result.json();
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    isLoading: isLoadingRoles,
    data: roles,
    refetch: refetchRoles,
  } = useQuery<Role[]>(
    'roles',
    async () => {
      const result = await getApi('user-roles');
      if (result.ok) {
        return result.json();
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    isLoading: isLoadingCurrencies,
    data: currencies,
    refetch: refetchCurrencies,
  } = useQuery<Currency[]>(
    'currencies',
    async () => {
      const result = await getApi('currencies');
      if (result.ok) {
        return result.json();
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    isLoading: isLoadingChargeCodes,
    data: chargeCodes,
    refetch: refetchChargeCodes,
  } = useQuery<ChargeCode[]>(
    'chargeCodes',
    async () => {
      const result = await getApi('charge-codes');
      if (result.ok) {
        return result.json();
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const values = useMemo(
    () => ({
      countries: {
        data: countries,
        isLoading: isLoadingCountries,
        refetch: refetchCountries,
      },
      roles: {
        data: roles,
        isLoading: isLoadingRoles,
        refetch: refetchRoles,
      },
      currencies: {
        data: currencies,
        isLoading: isLoadingCurrencies,
        refetch: refetchCurrencies,
      },
      chargeCodes: {
        data: chargeCodes,
        isLoading: isLoadingChargeCodes,
        refetch: refetchChargeCodes,
      },
    }),
    [
      chargeCodes,
      countries,
      currencies,
      isLoadingChargeCodes,
      isLoadingCountries,
      isLoadingCurrencies,
      isLoadingRoles,
      refetchChargeCodes,
      refetchCountries,
      refetchCurrencies,
      refetchRoles,
      roles,
    ],
  );

  return <DataContext.Provider value={values}>{children}</DataContext.Provider>;
};

import {useMemo} from 'react';
import type {Column} from 'react-table';
import {TransportMode} from '../../../../types';
import {MethodTariffAbbreviation} from '../../../../types/MethodTariffAbbreviation';
import {TariffTruckingDto} from '../../../../types/api/TariffTruckingDto';
import {toDisplayDateFormat} from '../../../../utils/formatter';
import {getAssignedPorts} from '../../../../utils/model/getAssignedPorts';
import {getCalculatorType} from '../../../../utils/model/getCalculatorType';

export const useTruckingColumns = () => {
  const columns = useMemo<Column<TariffTruckingDto>[]>(
    () => [
      {
        Header: 'Name',
        accessor: (rate) => rate.name,
        width: '110',
      },
      {
        Header: 'Transport Type',
        accessor: (rate) => TransportMode[rate.transportMode],
        width: '120',
      },
      {
        Header: 'Method',
        accessor: (rate) =>
            rate.method?.map((item) => MethodTariffAbbreviation[item]).join(', '),
        width: '110',
      },
      {
        Header: 'Direction',
          accessor: (rate) => rate.direction?.map((item) => item).join(', '),
        width: '110',
      },
      {
        Header: 'Assigned Ports',
        accessor: (rate) =>
          getAssignedPorts(rate.assignedPorts, rate.transportMode),
      },
      {
        Header: 'Calculator',
        accessor: (rate) => getCalculatorType(rate.calculator),
      },
      {
        Header: 'Currency',
        accessor: (rate) => rate.currency.code,
        width: '110',
      },
      {
        Header: 'Valid From',
        accessor: (rate) =>
          rate.tariffID === '' ? '' : toDisplayDateFormat(rate.validFrom),
      },
      {
        Header: 'Valid To',
        accessor: (rate) =>
          rate.tariffID === '' ? '' : toDisplayDateFormat(rate.validTo),
      },
    ],
    [],
  );
  return { columns };
};

import { useTheme } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Column } from 'react-table';
import { IfApplicableTable } from '../../../components/IfApplicableTable';
import { RateTable } from '../../../components/RateTable';
import { NoResult } from '../../../components/Result';
import { useErrorToast } from '../../../hooks/useErrorToast';
import { useApi } from '../../../providers/ApiProvider';
import { Rate, RateType, TransportMode } from '../../../types';
import { IfApplicable } from '../../../types/IfApplicable';
import { toDisplayDateFormat } from '../../../utils/formatter';
import { getHttpStatusDescription } from '../../../utils/httpStatus';

const errorTitle = 'Saving your changes failed, please try again';

interface IIdObject {
  id: string;
}

interface IExpiredRatesModalBodyProps<
  TRateType extends Omit<Rate, 'organizationId'>,
  TRateFormValues,
  TRateUpdateResponse,
> {
  isExpired: boolean;
  typeEndpoint: string;
  additionalColumns: Column<TRateType>[];
  rateType: RateType;
  createUpdatedRate: (
    submitData: TRateFormValues,
    rate?: TRateType,
  ) => TRateUpdateResponse;
}

export const ExpiredRatesModalBody = <
  TRateType extends Omit<Rate, 'organizationId'>,
  TRateFormValues extends object,
  TRateUpdateResponse extends IIdObject,
>({
  isExpired,
  additionalColumns,
  typeEndpoint,
  createUpdatedRate,
  rateType,
}: IExpiredRatesModalBodyProps<
  TRateType,
  TRateFormValues,
  TRateUpdateResponse
>) => {
  const { getApi, deleteApi, putApi } = useApi();
  const errorToast = useErrorToast();
  const [canvasContext, setCanvasContext] =
    useState<CanvasRenderingContext2D | null>(
      document.createElement('canvas').getContext('2d'),
    );
  const theme = useTheme();
  const tdFontText =
    theme.components.Table.baseStyle.tbody.tr.td.fontSize +
    ' ' +
    theme.fonts.body;
  useEffect(() => {
    if (canvasContext && canvasContext.font !== tdFontText) {
      canvasContext.font = tdFontText;
      setCanvasContext(canvasContext);
    }
  }, [canvasContext, tdFontText]);

  const { data, isLoading, isFetching, refetch } = useQuery<TRateType[]>(
    [],
    async () => {
      const result = await getApi(
        `${typeEndpoint}/expired?showSoonExpiring=${!isExpired}`,
      );

      if (result.ok) {
        return result.json();
      }
    },
  );

  const columns = useMemo<Column<TRateType>[]>(
    () => [
      ...additionalColumns,
      {
        Header: 'Valid From',
        accessor: (rate) => toDisplayDateFormat(rate.validFrom),
      },
      {
        Header: 'Valid To',
        accessor: (rate) => toDisplayDateFormat(rate.validTo),
      },
    ],
    [additionalColumns],
  );

  const onEdit = useCallback(
    async (submitData: TRateFormValues, rate?: TRateType) => {
      if (!rate) {
        return false;
      }

      let response: Response;

      const updatedRate = createUpdatedRate(submitData, rate);
      try {
        response = await putApi(
          `${typeEndpoint}/${updatedRate.id}`,
          updatedRate,
        );
      } catch (e) {
        errorToast({ title: errorTitle });
        return false;
      }

      if (response.ok) {
        await refetch();
        return true;
      }

      errorToast({ title: getHttpStatusDescription(response.status) });
      return false;
    },
    [createUpdatedRate, errorToast, putApi, typeEndpoint, refetch],
  );

  const onDelete = useCallback(
    async (id: string) => {
      let response;
      try {
        response = await deleteApi(`${typeEndpoint}/${id}`);
      } catch (e) {
        errorToast({ title: errorTitle });
        return false;
      }

      if (response.ok) {
        await refetch();
        return true;
      }

      errorToast({ title: getHttpStatusDescription(response.status) });
      return false;
    },
    [errorToast, deleteApi, typeEndpoint, refetch],
  );

  const {
    isLoading: isLoadingApplicableRates,
    data: ifApplicableRates,
    refetch: refetchIfApplicableRates,
  } = useQuery<IfApplicable[]>(['ifApplicable'], async () => {
    const result = await getApi(
      `rates/ifapplicables/expired?showSoonExpiring=${!isExpired}`,
    );
    if (result.ok) {
      return (await result.json()) as IfApplicable[];
    }

    return [];
  });

  const onDeleteIfApplicable = async (rateId: string) => {
    let response = null;
    try {
      response = await deleteApi(`rates/ifapplicables?rateId=${rateId}`);
    } catch (e) {
      errorToast({ title: errorTitle });
      return false;
    }

    if (response.ok) {
      await refetchIfApplicableRates();
      return true;
    }
    errorToast({ title: getHttpStatusDescription(response.status) });
    return false;
  };

  const onEditIfApplicable = async (submitData: IfApplicable) => {
    let response = null;
    try {
      response = await putApi(
        `rates/ifapplicables?rateId=${submitData.infoId}`,
        submitData,
      );
    } catch (e) {
      errorToast({ title: errorTitle });
      return false;
    }

    if (response.ok) {
      refetchIfApplicableRates();
      return true;
    }
    errorToast({
      title:
        response.status === 409 || response.status === 400
          ? await response.text()
          : getHttpStatusDescription(response.status),
    });
    return false;
  };

  return (
    <>
      {data && data.length > 0 && (
        <RateTable<TRateType, TRateFormValues>
          columns={columns}
          onEdit={onEdit}
          onDelete={onDelete}
          rateType={rateType}
          isLoading={isLoading || isFetching}
          fetchFailed={false}
          statusCode={200}
          empty={<NoResult />}
          showActionColumn={true}
          data={data}
        />
      )}
      {rateType === RateType.Local &&
        ifApplicableRates &&
        ifApplicableRates.length > 0 && (
          <IfApplicableTable
            canvasContext={canvasContext}
            ifApplicables={ifApplicableRates ?? []}
            renderType={TransportMode.LCL}
            isLoading={isLoadingApplicableRates}
            onDelete={onDeleteIfApplicable}
            onEdit={onEditIfApplicable}
            showActionColumn={true}
            hasFilters={false}
          />
        )}
    </>
  );
};

import { ReactNode } from 'react';
import type { Address } from './Address';
import type { Currency } from './Currency';
import { IfApplicable } from './IfApplicable';
import type { Port } from './Port';
import type { TransportMode } from './Rate';
import { WeightUnit } from './Unit';

export enum ChargeGroup {
  Undefined = 0,
  Main = 1,
  OriginPort = 2,
  DestinationPort = 3,
  OriginTransport = 4,
  DestinationTransport = 5,
}

export enum FreightType {
  Undefined = 0,
  Package = 1,
  Container = 2,
}

export enum MainLegSource {
  Undefined = 1,
  PricingEngine = 2,
  WebCargo = 4,
  CargoSphere = 8,
  CargoOne = 16,
  HapagLloyd = 32,
}

export enum ShippingMode {
  Undefined = 0,
  Error = 1,
  Port2Port = 2,
  Door2Port = 3,
  Port2Door = 4,
  Door2Door = 5,
}

export interface QuotationCargoFormData {
  quantity: string | number;
  weight: string | number;
  weightUnit: number;
  width: string | number;
  height: string | number;
  length: string | number;
  lengthsUnit: number;
}

export interface QuotationFormData {
  cargo: QuotationCargoFormData[];
  containers: ContainerFormData | undefined;
  currency: string;
  originValue?: string;
  origin: Address | Port | undefined;
  originPortIds: string[] | undefined;
  destinationValue?: string;
  destination: Address | Port | undefined;
  destinationPortIds: string[] | undefined;
  transport: TransportMode;
}

export interface Package {
  freightID: string;
  weight: number;
  weightUnit: number;
  width: number;
  height: number;
  length: number;
  lengthsUnit: number;
  freightType: FreightType;
}

export enum ContainerSizeType {
  '20GP' = 1,
  '40GP' = 2,
  '40HC' = 3,
  '45HC' = 4,
}

export interface ContainerFormData {
  quantity: string | number;
  weight?: number;
  weightUnit: number;
  containerSizeType: ContainerSizeType;
}

export interface Container {
  weight?: number;
  weightUnit: number;
  containerSizeType: ContainerSizeType;
}

export interface Charge {
  baseCharge: number | undefined;
  baseCurrency: Currency;
  charge: number;
  chargeCode: {
    code: string;
    name: string;
  };
  chargeCodeID: string;
  chargeLineElementSource: MainLegSource;
  costTypeLCL?: LclMeasurementType;
  currency: Currency;
  hideRateInfo: boolean;
  chargeGroup: ChargeGroup;
  rateBreakdownType?: string;
  rateInfo?: number;
  isIncluded?: boolean;
}

export interface Quote {
  carrierName?: string;
  chargeableWeight: number;
  chargeableWeightT: number;
  charges: Charge[];
  containerQuantity?: number;
  containerSizeType: ContainerSizeType;
  currency: Currency;
  notifications: QuoteNotification[];
  quoteID: string;
  quoteNumber: string;
  rateType?: string;
  remarks?: string;
  scacCode?: string;
  shipment: {
    destinationAddress?: Address;
    destinationPort?: Port;
    originAddress?: Address;
    originPort?: Port;
    shippingMode: ShippingMode;
    transportMode: number;
  };
  sourceSystemReference?: string;
  totalVolumeCbm: number;
  totalWeight: number;
  totalWeightT: number;
  weightUnit: WeightUnit;
}

export interface QuoteNotification {
  message: ReactNode;
  type: QuoteNotificationType;
  highlight: string;
}

export interface QuoteRequest {
  transportMode: TransportMode;
  originPorts?: Port[];
  destinationPorts?: Port[];
  originAddress?: Address;
  destinationAddress?: Address;
  quotingSource: MainLegSource;
  dangerousGoods: boolean;
  weightUnit?: number;
  lengthsUnit?: number;
  outputCurrency: Currency;
  packages: Package[];
  container?: Container[];
}

export interface QuoteResponse {
  quote: Quote;
  quotingSource: MainLegSource;
  quoteStatus: QuoteStatus;
  ifApplicables: IfApplicable[];
  rateType: string;
}

export interface QuoteStatus {
  status: Status;
  maxLengthPackagesCm: number;
  maxWidthPackagesCm: number;
  maxHeightPackagesCm: number;
  maxWeightPackagesKg: number;
  maxVolumePackagesCbm: number;
}

export enum Status {
  OK = 0,
  AIR_FREIGHT_ERROR = 1,
  LCL_ERROR = 2,
  NOT_FOUND = 3,
}

export enum LclMeasurementType {
  Undefined = 0,
  Weight = 1,
  Volume = 2,
}

export enum QuoteNotificationType {
  Info = 0,
  Warning = 1,
  Error = 2,
}

import { Address } from './Address';
import { TransportMode } from './Rate';

export enum PortDeterminationSource {
  Undefined = 0,
  InternalBasedOnCountry = 1,
  InternalBasedOnTariff = 2,
  External = 10,
}

export interface PortDeterminationInfo {
  transportType: TransportMode;
  portDeterminationOrigin: PortDeterminationSource;
  portDeterminationDestination: PortDeterminationSource;
  originAddress?: Address;
  destinationAddress?: Address;
}

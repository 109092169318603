import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Button,
  Grid,
  GridItem,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FunctionComponent, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useParams } from 'react-router-dom';
import { EditIcon } from '../../components/Icons';
import { HeadingCard, MainCard } from '../../components/Layout';
import { ErrorResult } from '../../components/Result';
import { useApi } from '../../providers/ApiProvider';
import { useAuthentication } from '../../providers/AuthenticationProvider';
import { useAuthorization } from '../../providers/AuthorizationProvider';
import { User, UserRole } from '../../types';
import { routes } from '../../utils/routes';
import { InfoItem } from '../AccountPage/InfoItem';

export const UserDetailsPage: FunctionComponent = () => {
  const { id } = useParams();
  const { getApi } = useApi();
  const { userRole } = useAuthorization();
  const { user: loggedInUser } = useAuthentication();
  const [statusCode, setStatusCode] = useState<number>(0);
  const location = useLocation();

  const { isLoading, data: user } = useQuery<User>(['user', id], async () => {
    const result = await getApi(`users/${id}`);
    setStatusCode(result.status);
    if (result.ok) return result.json();
  });
  const filteredCountries = useMemo(() => {
    if (user?.role.role === UserRole.ORGANIZATION_MANAGER) {
      return user?.organization?.assignedCountries;
    }

    return user?.assignedCountries;
  }, [user]);

  const content = useMemo(() => {
    if (isLoading) {
      return <Skeleton height="200px" />;
    }

    if (user == null) {
      return <ErrorResult statusCode={statusCode} />;
    }

    return (
      <Grid
        templateColumns={{ base: 'repeat(1, auto)', md: 'repeat(2, 1fr)' }}
        gap="2"
      >
        <GridItem>
          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
            <InfoItem title="Name" value={user.name ?? '-'} />
            <InfoItem title="Email" value={user.email ?? '-'} />
            <InfoItem title="Country" value={user.userCountry?.name ?? '-'} />
            <InfoItem
              title="Organization"
              value={user.organization?.name ?? '-'}
            />
          </Grid>
        </GridItem>
        <GridItem>
          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
            <InfoItem
              title="Assigned countries"
              value={
                filteredCountries?.length === 0
                  ? 'None'
                  : filteredCountries?.map((country) => (
                      <Text key={country.countryID}>{country.name}</Text>
                    ))
              }
            />
            <InfoItem title="Role" value={user.role.name} />
          </Grid>
        </GridItem>
      </Grid>
    );
  }, [filteredCountries, isLoading, statusCode, user]);

  const hasLowerUserRole =
    (user?.role.role ?? UserRole.GLOBAL_ADMINISTRATOR) < userRole;
  const hasLowerOrEqualOrgManagerPlusRole =
    userRole >= UserRole.ORGANIZATION_MANAGER &&
    (user?.role.role ?? UserRole.GLOBAL_ADMINISTRATOR) <= userRole;
  const isLoggedInUser = user?.userID === loggedInUser?.userID;

  return (
    <Stack>
      <HeadingCard
        heading={
          <>
            <Button
              as={Link}
              to={location.state?.backTo ?? routes.userManagement.base}
              variant="outline"
              size="xs"
              mr="1rem"
              leftIcon={<ChevronLeftIcon h="4" w="4" />}
            >
              Back
            </Button>
            {user?.name}
          </>
        }
        direction="row"
        align="center"
        justify="space-between"
      >
        {(hasLowerUserRole ||
          hasLowerOrEqualOrgManagerPlusRole ||
          isLoggedInUser) && (
          <Button
            as={Link}
            to={routes.userManagement.edit(id!)}
            state={{ backTo: location.state?.backTo }}
            colorScheme="secondary"
            color="grey.100"
            size="xs"
            rightIcon={<EditIcon w="5" h="5" />}
          >
            Edit
          </Button>
        )}
      </HeadingCard>
      <MainCard heading="Details">{content}</MainCard>
    </Stack>
  );
};

import {
  Box,
  Button,
  Center,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useMemo, useRef } from 'react';
import {
  FilterTypes,
  useFilters,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import type { Column } from 'react-table';
import { useContainerDimensions } from '../../hooks/useContainerDimensions';
import { TableContext } from '../../hooks/useTableContext';
import { TextColumnFilter } from './TextColumnFilter';

export interface DataTableBodyProps<T extends object> {
  data?: T[];
  columns: Column<T>[];
  variant?: string;
  enableFilters?: boolean;
  showLoadAllButton?: boolean;
  sizeOfPage?: () => number | undefined;
  updatePageSize?: (newSize: number) => void;
  minHeight?: string;
  narrowRows?: boolean;
  disableMargin?: boolean;
}

export const DataTableBody = <T extends object>({
  data,
  columns,
  variant = 'faded',
  enableFilters = false,
  showLoadAllButton = false,
  sizeOfPage,
  updatePageSize,
  minHeight = 'auto',
  narrowRows = false,
  disableMargin = false,
}: DataTableBodyProps<T>) => {
  const filterTypes: FilterTypes<T> = useMemo(
    () => ({
      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const value = row.values[id.toString()];
          return String(value)
            .toLowerCase()
            .includes(String(filterValue).toLowerCase());
        }),
    }),
    [],
  );

  const cellRef = useRef<HTMLTableDataCellElement>(null);
  const { width } = useContainerDimensions(cellRef);
  const isSticky: boolean = variant === 'sticky';
  const isFaded: boolean = variant != null && variant.startsWith('faded');

  const defaultColumn: Partial<Column<T>> = useMemo(
    () => ({
      Filter: TextColumnFilter,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    nextPage,
    canNextPage,
    setAllFilters,
    setPageSize,
    preFilteredRows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: data ?? [],
      initialState: {
        pageIndex: 0,
        pageSize: (sizeOfPage?.() ?? data?.length === 11) ? 11 : 10,
      },
      filterTypes,
      defaultColumn,
      disableFilters: !enableFilters,
    },
    useFilters,
    useSortBy,
    usePagination,
    useFlexLayout,
  );

  let size = sizeOfPage?.();

  //Guard to correct size in case of react-table not acting fast enough
  if (size !== undefined && pageSize !== size) {
    setPageSize(size);
  }

  /**
   * Updates internal state as well as parent state
   * @param newSize new page size of the table
   */
  const setNewSize = (newSize: number) => {
    setPageSize(newSize);
    if (updatePageSize) updatePageSize(newSize);
  };

  /**
   * Renders fadeout overlay next to last div to allow fadeout effect
   * next to still visible edit button
   * @param index index of the cell
   * @param arrLen number of the cells in the specific row
   */
  const renderFadeoutDiv = (
    index: number,
    arrLen: number,
  ): React.ReactNode | undefined =>
    index === arrLen - 1 && (
      <Box
        backgroundColor="grey.800"
        zIndex="15"
        position="absolute"
        height="100%"
        sx={{
          mask: 'linear-gradient(to right,transparent, black 60%);',
          right: width,
          width: 30,
        }}
        _groupHover={{
          display: 'none',
        }}
      ></Box>
    );

  return (
    <Stack
      mt={disableMargin ? '0' : '8'}
      spacing="8"
      overflowX="auto"
      pb="4"
      minHeight={minHeight}
    >
      <Table {...getTableProps()} variant={variant}>
        <Stack as={Thead}>
          <TableContext.Provider value={{ setAllFilters }}>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th textTransform="none" px="2" {...column.getHeaderProps()}>
                    <Box textTransform="uppercase" px="2">
                      {column.render('Header')}
                    </Box>
                    <Box mt="2">
                      {column.canFilter ? column.render('Filter') : null}
                    </Box>
                  </Th>
                ))}
                {isFaded && <Th width="10px"></Th>}
              </Tr>
            ))}
          </TableContext.Provider>
        </Stack>
        <Stack as={Tbody} spacing="2" {...getTableBodyProps()}>
          {rows.slice(0, pageIndex * pageSize + pageSize).map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} role="group">
                <>
                  {row.cells.map((cell, index, arr) => (
                    <>
                      <Td
                        role="group"
                        ref={cellRef}
                        {...cell.getCellProps()}
                        paddingY={narrowRows ? '2' : '4'}
                        paddingX={narrowRows ? '4' : '6'}
                      >
                        {isSticky && renderFadeoutDiv(index, arr.length)}
                        {cell.render('Cell')}
                      </Td>
                    </>
                  ))}
                  {isFaded && <Td width="10px"></Td>}
                </>
              </Tr>
            );
          })}
        </Stack>
      </Table>
      {canNextPage && (
        <Center>
          <Button
            colorScheme="blue"
            onClick={() =>
              showLoadAllButton
                ? setNewSize(preFilteredRows.length)
                : nextPage()
            }
          >
            {showLoadAllButton ? 'Load all' : 'Load more'}
          </Button>
        </Center>
      )}
    </Stack>
  );
};

import { DeleteIcon } from '@chakra-ui/icons';
import { IconButton, Stack, useBreakpointValue, Box } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import type { Control } from 'react-hook-form';
import { CircledPlusIcon } from '../../../components/Icons';
import { NumberFormInput, SelectFormInput } from '../../../components/Input';
import type { FormInputProps } from '../../../components/Input';
import type { QuotationCargoFormData, Unit } from '../../../types';

export interface CargoInputProps extends FormInputProps {
  control: Control<any>;
  showDelete?: boolean;
  lengthUnits: Unit[];
  weightUnits: Unit[];
  defaultValue?: QuotationCargoFormData;
  onDelete: () => void;
  value: QuotationCargoFormData;
  isDisabled: boolean;
  onAdd: () => void;
  showAddBtn: boolean;
}

export const CargoInput: FunctionComponent<CargoInputProps> = ({
  control,
  accessor,
  lengthUnits,
  weightUnits,
  defaultValue,
  onDelete,
  showDelete,
  value,
  isDisabled,
  onAdd,
  showAddBtn,
}) => {
  const onZoom = useBreakpointValue({ base: true, xl: false });
  return (
    <>
      <Stack spacing={2} direction={onZoom ? 'column' : 'row'}>
        <NumberFormInput
          label="Quantity"
          accessor={accessor + '.quantity'}
          control={control}
          defaultValue={defaultValue && defaultValue.quantity}
          precision={0}
          min={1}
          max={999}
          useButtons={true}
          isRequired={true}
          isDisabled={isDisabled}
          value={value.quantity}
        />
        <NumberFormInput
          label="Length"
          accessor={accessor + '.length'}
          control={control}
          defaultValue={defaultValue && defaultValue.length}
          precision={2}
          min={0}
          max={9999}
          isRequired={true}
          isDisabled={isDisabled}
          value={value.length}
        />
        <NumberFormInput
          label="Width"
          accessor={accessor + '.width'}
          control={control}
          defaultValue={defaultValue && defaultValue.width}
          precision={2}
          min={0}
          max={9999}
          isRequired={true}
          isDisabled={isDisabled}
          value={value.width}
        />
        <NumberFormInput
          label="Height"
          accessor={accessor + '.height'}
          control={control}
          defaultValue={defaultValue && defaultValue.height}
          precision={2}
          min={0}
          max={9999}
          isRequired={true}
          isDisabled={isDisabled}
          value={value.height}
        />
        <SelectFormInput
          label="Unit"
          accessor={accessor + '.lengthsUnit'}
          value={{
            value: defaultValue?.lengthsUnit,
            label: lengthUnits.find(
              (unit) => unit.code === defaultValue?.lengthsUnit,
            )?.name,
          }}
          defaultValue={{
            value: defaultValue?.lengthsUnit,
            label: lengthUnits.find(
              (unit) => unit.code === defaultValue?.lengthsUnit,
            )?.name,
          }}
          controllerDefaultValue={defaultValue?.lengthsUnit}
          isRequired={true}
          isDisabled={isDisabled}
          placeholder="Select unit"
          control={control}
          options={lengthUnits.map((unit) => ({
            label: unit.name,
            value: unit.code,
          }))}
        />
      </Stack>
      <Stack
        spacing={2}
        direction={onZoom ? 'column' : 'row'}
        w={onZoom ? '100%' : 'fit-content'}
        align="center"
      >
        <NumberFormInput
          label="Weight per piece"
          accessor={accessor + '.weight'}
          control={control}
          defaultValue={defaultValue && defaultValue.weight}
          precision={2}
          min={0}
          max={99999}
          isRequired={true}
          isDisabled={isDisabled}
          value={value.weight}
          minW="8rem"
        />
        <SelectFormInput
          control={control}
          label="Unit"
          accessor={accessor + '.weightUnit'}
          value={{
            value: defaultValue?.weightUnit,
            label: weightUnits.find(
              (unit) => unit.code === defaultValue?.weightUnit,
            )?.name,
          }}
          defaultValue={{
            value: defaultValue?.weightUnit,
            label: weightUnits.find(
              (unit) => unit.code === defaultValue?.weightUnit,
            )?.name,
          }}
          controllerDefaultValue={defaultValue?.weightUnit}
          isRequired={true}
          placeholder="Select unit"
          isDisabled={isDisabled}
          options={weightUnits.map((unit) => ({
            label: unit.name,
            value: unit.code,
          }))}
        />
        {!onZoom && (
          <Box alignSelf="flex-end" pb="1">
            <IconButton
              icon={<DeleteIcon />}
              aria-label="Delete"
              color="red.500"
              size="sm"
              variant="ghost"
              disabled={!showDelete || isDisabled}
              onClick={() => onDelete()}
            />
          </Box>
        )}
        {showAddBtn && (
          <Box alignSelf={onZoom ? 'center' : 'flex-end'} pb="2">
            <IconButton
              icon={<CircledPlusIcon w="6" h="6" color="blue.500" />}
              variant="ghost"
              colorScheme="transparent"
              size="xs"
              isDisabled={isDisabled}
              onClick={() => onAdd()}
              aria-label="Add Cargo"
            />
          </Box>
        )}
      </Stack>
    </>
  );
};

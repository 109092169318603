import { DeleteIcon } from '@chakra-ui/icons';
import { Button, HStack } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import { PlusIcon, EditIcon } from '../../../../components/Icons';
import { HeadingCard } from '../../../../components/Layout';
import { useAuthentication } from '../../../../providers/AuthenticationProvider';
import { useAuthorization } from '../../../../providers/AuthorizationProvider';
import { routes } from '../../../../utils/routes';
import { checkCanMutateRates } from '../../utils/ratePermissions';

export interface TariffHeadingProps {
  title: string;
  onSave: () => void;
  onCancel: () => void;
  onEdit: () => void;
  onDeactivate: () => void;
  onDuplicate: () => void;
  isEdit?: boolean;
  countryId: string;
}

export const TariffHeading: FunctionComponent<TariffHeadingProps> = ({
  title,
  onSave,
  onCancel,
  onEdit,
  onDeactivate,
  onDuplicate,
  isEdit,
  countryId,
}) => {
  const navigate = useNavigate();
  const { user } = useAuthentication();
  const { userRole } = useAuthorization();

  const canMutate = checkCanMutateRates(userRole, user, [countryId]);

  const handleTariffBack = () => {
    navigate(routes.charges.trucking);
  };

  const handleOnDeactivate = () => {
    onDeactivate();
  };

  const handleDuplicateClick = () => {
    onDuplicate();
  };

  return (
    <>
      <HeadingCard
        heading={title}
        direction="row"
        align="center"
        justify="space-between"
        onBack={handleTariffBack}
      >
        {canMutate && !isEdit && (
          <HStack>
            <Button
              colorScheme="red"
              size="xs"
              onClick={handleOnDeactivate}
              rightIcon={<DeleteIcon w="4" h="4" />}
            >
              Deactivate
            </Button>
            <Button
              colorScheme="secondary"
              size="xs"
              onClick={handleDuplicateClick}
              rightIcon={<PlusIcon w="4" h="4" />}
            >
              Duplicate
            </Button>
            <Button
              colorScheme="secondary"
              size="xs"
              onClick={onEdit}
              rightIcon={<EditIcon w="5" h="5" />}
            >
              Edit
            </Button>
          </HStack>
        )}
        {canMutate && isEdit && (
          <HStack>
            <Button
              variant="ghost"
              colorScheme="white"
              size="sm"
              _hover={{ bgColor: 'grey.600' }}
              onClick={onCancel}
              mr={3}
            >
              Cancel
            </Button>
            <Button colorScheme="blue" size="sm" onClick={onSave}>
              Save
            </Button>
          </HStack>
        )}
      </HeadingCard>
    </>
  );
};

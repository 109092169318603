import { CheckIcon } from '@chakra-ui/icons';
import {
  Button,
  Center,
  Divider,
  Heading,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import type { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { Column } from 'react-table';
import { DataTable } from '../../../components/DataTable';
import { CloseIcon } from '../../../components/Icons';
import { useApi } from '../../../providers/ApiProvider';
import type { Organization } from '../../../types';
import { ApprovalStatus } from '../../../types';
import { CreateOrganizationModal } from './CreateOrganizationModal';
import { DeclineOrganizationModal } from './DeclineOrganizationModal';

interface PendingOrganizationsTableProps {
  refetchOrganizations: () => void;
}

export const PendingOrganizationsTable: FunctionComponent<
  PendingOrganizationsTableProps
> = ({ refetchOrganizations }) => {
  const { getApi } = useApi();
  const [statusCode, setStatusCode] = useState<number>(0);
  const [fetchFailed, setFetchFailed] = useState<boolean>(false);
  const [currentOrganization, setCurrentOrganization] =
    useState<Organization>();
  const {
    isOpen: isCreateOpen,
    onOpen: onCreateOpen,
    onClose: onCreateClose,
  } = useDisclosure();
  const {
    isOpen: isDeclineOpen,
    onOpen: onDeclineOpen,
    onClose: onDeclineClose,
  } = useDisclosure();

  const columns = useMemo<Column<Organization>[]>(
    () => [
      {
        Header: 'Name',
        width: '100',
        accessor: (org) => org.name,
      },
      {
        Header: 'Location',
        width: '100',
        accessor: (org) => org.mainCountry?.name ?? '-',
      },
      {
        id: 'action',
        width: '200',
        accessor: (org) => (
          <>
            <Spacer />
            <Button
              variant="ghost"
              color="green.500"
              fontSize="xs"
              _hover={{ bgColor: 'green.500', color: 'grey.800' }}
              rightIcon={<CheckIcon w="4" h="4" />}
              onClick={() => {
                setCurrentOrganization(org);
                onCreateOpen();
              }}
            >
              Approve
            </Button>
            <Center h="1.5rem" px="0.5rem">
              <Divider
                borderColor="grey.700"
                borderBottomWidth="2px"
                opacity="unset"
                orientation="vertical"
              />
            </Center>
            <Button
              variant="ghost"
              colorScheme="white"
              _hover={{ bgColor: 'red.500', color: 'grey.800' }}
              color="red.500"
              fontSize="xs"
              rightIcon={<CloseIcon w="4" h="4" />}
              onClick={() => {
                setCurrentOrganization(org);
                onDeclineOpen();
              }}
            >
              Decline
            </Button>
          </>
        ),
      },
    ],
    [onCreateOpen, onDeclineOpen],
  );

  const { isLoading, data, refetch } = useQuery<Organization[]>(
    ['pendingOrganizations'],
    async () => {
      setFetchFailed(false);
      const result = await getApi(
        `organizations?onlyActive=false&approvalStatus=${ApprovalStatus.PENDING}`,
      );
      setStatusCode(result.status);
      if (result.ok) return result.json();
      setFetchFailed(true);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <>
      <Heading as="h2" variant="h2">
        Pending Requests
      </Heading>
      <DataTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        fetchFailed={fetchFailed}
        statusCode={statusCode}
      />
      <CreateOrganizationModal
        onClose={() => {
          onCreateClose();
          refetch();
          refetchOrganizations();
        }}
        isOpen={isCreateOpen}
        data={currentOrganization}
      />
      <DeclineOrganizationModal
        onClose={() => {
          onDeclineClose();
          refetch();
        }}
        isOpen={isDeclineOpen}
        organizationId={currentOrganization?.organizationID}
      />
    </>
  );
};

import { Divider, GridItem, SimpleGrid, Text } from '@chakra-ui/layout';
import { FormLabel, Grid, Stack, Tooltip } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useWatch } from 'react-hook-form';
import shallow from 'zustand/shallow';
import { useTruckingFiltersOld } from '../../../hooks/useTruckingFilterOld';
import { useData } from '../../../providers/DataProvider';
import { TransportMode } from '../../../types/Rate';
import { FormInput, NumberFormInput } from '../../Input';
import type { FieldProps } from './LocalChargeFields';

interface TruckingChargeFieldProps extends FieldProps {
  errors: any;
}

export const TruckingChargeFields: FunctionComponent<
  TruckingChargeFieldProps
> = ({ register, errors, control, getValues }) => {
  const { countries } = useData();
  const { countryId, transportType } = useTruckingFiltersOld(
    (state) => ({
      countryId: state.countryId,
      transportType: state.transportType,
    }),
    shallow,
  );

  const watchBelow100kg = useWatch({ control, name: 'below100Kg' });
  const watchBelow300kg = useWatch({ control, name: 'below300Kg' });
  const watchBelow500kg = useWatch({ control, name: 'below500Kg' });
  const watchBelow1000kg = useWatch({ control, name: 'below1000Kg' });
  const watchBelow3000kg = useWatch({ control, name: 'below3000Kg' });
  const watchBelow5000kg = useWatch({ control, name: 'below5000Kg' });

  const countryIdValue = getValues('country.countryID') ?? countryId;
  const transportModeValue = getValues('transportMode') ?? transportType;

  return (
    <Stack spacing="4">
      <SimpleGrid columns={2} gap="4" w={{ base: 'full', lg: '50%' }}>
        <FormLabel mb="0">Country</FormLabel>
        <Text fontSize="xs">
          {
            countries.data?.find(
              (country) => country.countryID === countryIdValue,
            )?.name
          }
        </Text>
        <FormLabel mb="0">Transport Type</FormLabel>
        <Text fontSize="xs">{TransportMode[transportModeValue]}</Text>
      </SimpleGrid>
      <Divider borderColor="grey.900" borderWidth="1px" opacity="unset" />
      <Grid
        gap={{ base: 8, lg: 10 }}
        templateColumns={{
          base: 'repeat(1, 1fr)',
          lg: 'repeat(2, 1fr)',
        }}
      >
        <GridItem>
          <FormInput
            label="Distance Zone"
            accessor="distanceZone.distanceFrom"
            register={register}
            errors={errors}
            isRequired={true}
            isDisabled
          />
        </GridItem>
        <GridItem>
          <FormInput
            label="Distance Zone"
            accessor="distanceZone.distanceTo"
            register={register}
            errors={errors}
            isRequired={true}
            isDisabled
          />
        </GridItem>
      </Grid>
      <Stack>
        <Grid gap={{ base: 8, lg: 10 }} templateColumns={'repeat(3, 1fr)'}>
          <GridItem>
            <NumberFormInput
              label="Min"
              accessor="minimumCharge"
              control={control}
              precision={2}
              step={1.0}
              isRequired={true}
            />
          </GridItem>
          <GridItem>
            <NumberFormInput
              validate={() => {
                if (watchBelow300kg) {
                  return watchBelow100kg ? true : 'Field must be filled';
                }
              }}
              label="Below 100Kg"
              accessor="below100Kg"
              control={control}
              precision={2}
              step={1.0}
            />
          </GridItem>
          <Tooltip
            isDisabled={watchBelow100kg ? true : false}
            label="Fill previous weight bracket to proceed"
          >
            <GridItem>
              <NumberFormInput
                validate={() => {
                  if (watchBelow500kg) {
                    return watchBelow300kg ? true : 'Field must be filled';
                  }
                }}
                label="Below 300Kg"
                accessor="below300Kg"
                control={control}
                precision={2}
                step={1.0}
                isDisabled={watchBelow100kg ? false : true}
              />
            </GridItem>
          </Tooltip>
          <Tooltip
            isDisabled={watchBelow300kg ? true : false}
            label="Fill previous weight bracket to proceed"
          >
            <GridItem>
              <NumberFormInput
                validate={() => {
                  if (watchBelow1000kg) {
                    return watchBelow500kg ? true : 'Field must be filled';
                  }
                }}
                label="Below 500Kg"
                accessor="below500Kg"
                control={control}
                precision={2}
                step={1.0}
                isDisabled={watchBelow300kg ? false : true}
              />
            </GridItem>
          </Tooltip>
          <Tooltip
            isDisabled={watchBelow500kg ? true : false}
            label="Fill previous weight bracket to proceed"
          >
            <GridItem>
              <NumberFormInput
                validate={() => {
                  if (watchBelow3000kg) {
                    return watchBelow1000kg ? true : 'Field must be filled';
                  }
                }}
                label="Below 1000Kg"
                accessor="below1000Kg"
                control={control}
                precision={2}
                step={1.0}
                isDisabled={watchBelow500kg ? false : true}
              />
            </GridItem>
          </Tooltip>
          <Tooltip
            isDisabled={watchBelow1000kg ? true : false}
            label="Fill previous weight bracket to proceed"
          >
            <GridItem>
              <NumberFormInput
                validate={() => {
                  if (watchBelow5000kg) {
                    return watchBelow3000kg ? true : 'Field must be filled';
                  }
                }}
                label="Below 3000Kg"
                accessor="below3000Kg"
                control={control}
                precision={2}
                step={1.0}
                isDisabled={watchBelow1000kg ? false : true}
              />
            </GridItem>
          </Tooltip>
          <Tooltip
            isDisabled={watchBelow3000kg ? true : false}
            label="Fill previous weight bracket to proceed"
          >
            <GridItem>
              <NumberFormInput
                label="Below 5000Kg"
                accessor="below5000Kg"
                control={control}
                precision={2}
                step={1.0}
                isDisabled={watchBelow3000kg ? false : true}
              />
            </GridItem>
          </Tooltip>
        </Grid>
      </Stack>
    </Stack>
  );
};

import { DeleteIcon } from '@chakra-ui/icons';
import {
  Divider,
  GridItem,
  Heading,
  HStack,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  SimpleGridProps,
  Stack,
} from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { As, Button, Grid } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { SelectInstance } from 'react-select';
import { useDebounce } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';
import { BoatIcon, LocalIcon, PlaneIcon } from '../../../components/Icons';
import {
  MultiSelectFormInput,
  SelectFormInput,
  SelectInputOption,
} from '../../../components/Input';
import { RadioFormInput } from '../../../components/Input/RadioFormInput';
import { SearchInput } from '../../../components/Input/SearchInput';
import { valueOrDefault } from '../../../components/LabelUtils';
import { Card, MainCard } from '../../../components/Layout';
import { ListHeading } from '../../../components/Layout/ListHeading';
import { useErrorToast } from '../../../hooks/useErrorToast';
import { useQuotationState } from '../../../hooks/useQuotationState';
import { useApi } from '../../../providers/ApiProvider';
import { useData } from '../../../providers/DataProvider';
import {
  Address,
  ContainerSizeType,
  FreightType,
  getPlaceTypeFromTransportMode,
  getPortTypeFromTransportMode,
  isAddress,
  isPort,
  LengthUnit,
  MainLegSource,
  Package,
  Place,
  PlaceType,
  Port,
  PortType,
  QuotationCargoFormData,
  QuotationFormData,
  QuoteRequest,
  QuoteResponse,
  Status,
  TransportMode,
  Unit,
  WeightUnit,
} from '../../../types';
import { AlgoliaPlace } from '../../../types/AlgoliaPlace';
import { PlaceResponse } from '../../../types/PlaceResponse';
import {
  PortDeterminationInfo,
  PortDeterminationSource,
} from '../../../types/PortDeterminationInfo';
import {
  convertLengthUnit,
  convertWeightUnit,
} from '../../../utils/UnitConversion';
import { getHttpStatusDescription } from '../../../utils/httpStatus';
import { InfoItem } from '../../AccountPage/InfoItem';
import { CargoInput } from './CargoInput';
import { ContainerInput } from './ContainerInput';
import { PlaceDetails } from './PlaceDetails';

const getDefaultPackageItem = () =>
  ({
    quantity: 1,
    weight: '',
    weightUnit: 2,
    width: '',
    height: '',
    length: '',
    lengthsUnit: 52,
  }) as QuotationCargoFormData;

interface QuotationFormProps {
  lengthUnits: Unit[];
  weightUnits: Unit[];
  containerSizeTypes: Unit[];
}

export const QuotationForm: FunctionComponent<QuotationFormProps> = ({
  lengthUnits,
  weightUnits,
  containerSizeTypes,
}) => {
  const { getApi } = useApi();
  const onMobile = useBreakpointValue({ base: true, md: false });
  const onZoom = useBreakpointValue({ base: true, xl: false });
  const colSpanValue = useBreakpointValue({ base: 1, md: 2 });
  const { currencies } = useData();
  const { postApi } = useApi();
  const errorToast = useErrorToast();
  const { formData, setFormData, setQuotation } = useQuotationState();
  const [algoliaPlaces, setAlgoliaPlaces] = useState<AlgoliaPlace[]>([]);
  const originAdressPortsRef = useRef<
    SelectInstance<SelectInputOption[]> | undefined | null
  >(null);
  const destinationAdressPortsRef = useRef<
    SelectInstance<SelectInputOption[]> | undefined | null
  >(null);
  const simpleGridProps: SimpleGridProps = {
    columns: { base: 2, md: 4 },
    spacing: { base: '8', xl: '12' },
  };

  const defaultValues = useMemo(
    () => ({
      originValue: formData?.originValue ?? '',
      origin: formData?.origin,
      destinationValue: formData?.destinationValue ?? '',
      destination: formData?.destination,
      cargo: formData?.cargo ?? [getDefaultPackageItem()],
      containers: formData?.containers ?? {
        quantity: 1,
        weightUnit: WeightUnit.kg,
        containerSizeType: ContainerSizeType['20GP'],
        weight: undefined,
      },
      currency:
        formData?.currency ??
        (!currencies.isLoading && currencies.data
          ? currencies.data.find((currency) => currency.code === 'EUR')
              ?.currencyID
          : ''),
      transport: formData?.transport ?? TransportMode.Air,
    }),
    [currencies, formData],
  );

  const [debouncedOriginQuery] = useDebounce(formData?.originValue, 500);
  const [debouncedDestinationQuery] = useDebounce(
    formData?.destinationValue,
    500,
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    setValue,
    reset,
    watch,
  } = useForm<QuotationFormData>({
    defaultValues,
  });

  const { cargo, transport, origin, destination, currency, containers } =
    watch();

  const addPackage = useCallback(() => {
    setValue('cargo', [...cargo, getDefaultPackageItem()]);
  }, [cargo, setValue]);

  const removePackage = useCallback(
    (index: number) => {
      const list = [...cargo];
      list.splice(index, 1);
      setValue('cargo', list);
    },
    [cargo, setValue],
  );

  const placeToString = (item: Place | null) => item?.placeName ?? '';

  const displayPlaceResult = (
    item: Place,
    index: number,
    highlightedIndex: number | null,
    icon: As<any> | undefined,
    getItemProps: CallableFunction,
  ) => (
    <ListItem
      p="2"
      fontSize="xs"
      backgroundColor={highlightedIndex === index ? 'grey.500' : 'grey.800'}
      {...getItemProps({
        index,
        item,
      })}
    >
      <ListIcon as={icon} />
      {placeToString(item)}
    </ListItem>
  );

  const displayPlaceResults = (
    items: Place[],
    highlightedIndex: number | null,
    getItemProps: CallableFunction,
    getMenuProps: CallableFunction,
  ) => {
    const localPlaceType = getPlaceTypeFromTransportMode(transport);

    return (
      <List {...getMenuProps()}>
        {items
          .filter((item) => item.placeType === localPlaceType)
          .slice(0, 5)
          .map((item, index) => (
            <React.Fragment key={placeToString(item)}>
              {index === 0 ? (
                <ListHeading>
                  {localPlaceType === PlaceType.Airport ? 'Airports' : 'Ports'}
                </ListHeading>
              ) : null}
              {displayPlaceResult(
                item,
                index,
                highlightedIndex,
                localPlaceType === PlaceType.Airport ? PlaneIcon : BoatIcon,
                getItemProps,
              )}
            </React.Fragment>
          ))}
        {items
          .filter((item) => item.placeType === PlaceType.Address)
          .map((item, index) => (
            <React.Fragment key={placeToString(item)}>
              {index === 0 ? <ListHeading>Addresses</ListHeading> : null}
              {displayPlaceResult(
                item,
                (index + 1) * 10,
                highlightedIndex,
                LocalIcon,
                getItemProps,
              )}
            </React.Fragment>
          ))}
      </List>
    );
  };

  const { isLoading: isPortsLoading, data: ports } = useQuery<
    Port[] | undefined
  >(
    ['ports', transport],
    async () => {
      const portType = getPortTypeFromTransportMode(transport);
      const result = await getApi(
        portType ? `ports?portType=${portType}` : 'ports',
      );
      if (result.ok) {
        return (await result.json()) as Port[];
      }
    },
    { refetchOnWindowFocus: false },
  );

  const getPortCode = (port: Port) =>
    port.portType === PortType.Air
      ? (port.iataCode ?? '')
      : (port.unloCode ?? '');

  const getPortOptions = useCallback(
    (address: Address): SelectInputOption[] =>
      ports
        ? ports
            .filter((port) => port.country.code === address.countryCode)
            .map((port) => ({
              label: getPortCode(port),
              value: port.portID,
            }))
        : [],
    [ports],
  );

  const filterPortOptions = useCallback(
    (
      objectRef: MutableRefObject<
        SelectInstance<SelectInputOption[]> | undefined | null
      >,
    ) => {
      if (objectRef?.current && objectRef.current.getValue().length >= 3) {
        return false;
      }
      return true;
    },
    [],
  );

  const { isLoading: isLoadingOriginAddressPorts, data: originAddressPorts } =
    useQuery<Port[]>(
      ['originAddressPorts', transport, origin],
      async () => {
        if (isAddress(origin)) {
          const originPortDetermination: PortDeterminationInfo = {
            transportType: transport,
            originAddress: origin as Address,
            portDeterminationDestination: PortDeterminationSource.Undefined,
            portDeterminationOrigin: PortDeterminationSource.Undefined,
          };
          const result = await postApi(
            'portDetermination',
            originPortDetermination,
          );
          if (result.ok) {
            return (await result.json()) as Port[];
          }
        }
        return [];
      },
      { refetchOnWindowFocus: false },
    );

  const {
    isLoading: isLoadingDestinationAddressPorts,
    data: destinationAddressPorts,
  } = useQuery<Port[]>(
    ['destinationAddressPorts', transport, destination],
    async () => {
      if (isAddress(destination)) {
        const destinationPortDetermination: PortDeterminationInfo = {
          transportType: transport,
          destinationAddress: destination as Address,
          portDeterminationDestination: PortDeterminationSource.Undefined,
          portDeterminationOrigin: PortDeterminationSource.Undefined,
        };
        const result = await postApi(
          'portDetermination',
          destinationPortDetermination,
        );
        if (result.ok) {
          return (await result.json()) as Port[];
        }
      }
      return [];
    },
    { refetchOnWindowFocus: false },
  );

  const selectWeightUnit = (cargos: QuotationCargoFormData[]): WeightUnit => {
    if (cargos.length === 0) return WeightUnit.kg;
    return cargos[0].weightUnit;
  };
  const selectWeightUnitString = (cargos: QuotationCargoFormData[]): string =>
    WeightUnit[selectWeightUnit(cargos)];

  const calcVolume = (cargoFormData: QuotationCargoFormData) => {
    if (
      cargoFormData.width === '' ||
      cargoFormData.length === '' ||
      cargoFormData.height === ''
    )
      return 0;
    const desiredUnit = LengthUnit.m;
    return (
      convertLengthUnit(
        +cargoFormData.width,
        cargoFormData.lengthsUnit,
        desiredUnit,
      ) *
      convertLengthUnit(
        +cargoFormData.length,
        cargoFormData.lengthsUnit,
        desiredUnit,
      ) *
      convertLengthUnit(
        +cargoFormData.height,
        cargoFormData.lengthsUnit,
        desiredUnit,
      )
    );
  };

  const volumeReduce = (cargos: QuotationCargoFormData[]) =>
    cargos.reduce(
      (acc: number, selectedCargo: QuotationCargoFormData) =>
        acc + +selectedCargo.quantity * calcVolume(selectedCargo),
      0,
    );

  const weightReduce = (cargos: QuotationCargoFormData[]) => {
    const desiredUnit = selectWeightUnit(cargo);
    return cargos.reduce(
      (acc: number, selectedCargo: QuotationCargoFormData) =>
        acc +
        +selectedCargo.quantity *
          convertWeightUnit(
            +selectedCargo.weight,
            selectedCargo.weightUnit,
            desiredUnit,
          ),
      0,
    );
  };

  const comparePortToAlgolia = (
    transportMode: TransportMode,
    port: Port,
    algoliaPlace?: AlgoliaPlace,
  ) => {
    const isUnloCodeMatching =
      `${port.country?.code?.toLowerCase()}${port.unloCode?.toLowerCase()}` ===
      algoliaPlace?.locode?.toLowerCase();

    if (transportMode === TransportMode.Air) {
      const isIataCodeExistingAndMatching =
        algoliaPlace?.iata &&
        port.iataCode &&
        port.iataCode.toLowerCase() === algoliaPlace?.iata.toLowerCase();

      return isIataCodeExistingAndMatching || isUnloCodeMatching;
    }

    return isUnloCodeMatching;
  };

  const getPlaces = useCallback(
    async (query: string) => {
      if (query !== '') {
        try {
          const result = await getApi(
            `places?input=${query}&transportMode=${transport}`,
          );

          if (result.ok) {
            const places = (await result.json()) as PlaceResponse;
            const addressPlaces = places.googlePlaces.map((address) => ({
              ...address,
              placeType: PlaceType.Address,
            }));

            setAlgoliaPlaces(places.algoliaLocations);

            // This filtering is just to show the user only the ports we really know. Might not be necessary anymore when the dataquality of algolia improves
            const portPlaces = places.algoliaLocations
              .filter(
                (location) =>
                  ports?.find((port) =>
                    comparePortToAlgolia(transport, port, location),
                  ) != null,
              )
              .map((port) => ({
                placeId: port.objectID as string,
                placeName: [port.name, port.country_Name].join(', '),
                placeType: getPlaceTypeFromTransportMode(transport),
              }));

            return [...portPlaces, ...addressPlaces];
          }
        } catch {
          errorToast({
            title:
              'Something went wrong while getting locations. Please try again',
          });
          return [];
        }
      }
    },
    [errorToast, getApi, ports, transport],
  );

  const { isLoading: isOriginLoading, data: originItems } = useQuery<
    Place[] | undefined
  >(
    ['places', { debouncedOriginQuery }],
    async () => getPlaces(debouncedOriginQuery ?? ''),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const { isLoading: isDestinationLoading, data: destinationItems } = useQuery<
    Place[] | undefined
  >(
    ['places', { debouncedDestinationQuery }],
    async () => getPlaces(debouncedDestinationQuery ?? ''),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const getPlaceDetails = useCallback(
    async (place?: Place) => {
      if (place && place.placeType === PlaceType.Address) {
        const result = await getApi(`places/${place.placeId}`);
        if (result.ok) {
          return (await result.json()) as Address;
        }
      } else if (place && ports && algoliaPlaces) {
        const algoliaPlace = algoliaPlaces.find(
          (aPlace) => aPlace.objectID === place.placeId,
        );

        return ports.find((port) =>
          comparePortToAlgolia(transport, port, algoliaPlace),
        );
      }
    },
    [algoliaPlaces, getApi, ports, transport],
  );

  const onPlaceSelection = useCallback(
    async (place: Place | null, isOrigin: boolean) => {
      if (place) {
        const details = (await getPlaceDetails(place)) || '';

        if (details) {
          setValue(
            isOrigin ? 'origin' : 'destination',
            details as Address | Port,
          );
          setValue(
            isOrigin ? 'originValue' : 'destinationValue',
            placeToString(place),
          );
        }
      } else {
        setValue(isOrigin ? 'origin' : 'destination', undefined);
      }
    },
    [getPlaceDetails, setValue],
  );

  const onSubmit = useCallback(
    async (submitData: QuotationFormData) => {
      setFormData(submitData);

      const quoteRequest: QuoteRequest = {
        transportMode: submitData.transport,
        quotingSource: MainLegSource.Undefined,
        dangerousGoods: false,
        lengthsUnit: lengthUnits.find(
          (u) => u.code === submitData.cargo[0].lengthsUnit,
        )?.code,
        weightUnit: weightUnits.find(
          (u) => u.code === submitData.cargo[0].weightUnit,
        )?.code,
        outputCurrency: currencies?.data?.find(
          (c) => c.currencyID === submitData.currency,
        )!,
        packages: [] as Package[],
        container: undefined,
      };

      if (isPort(submitData.origin)) {
        quoteRequest.originPorts = [submitData.origin] as Port[];
      } else if (isAddress(submitData.origin)) {
        quoteRequest.originAddress = submitData.origin as Address;
        quoteRequest.originPorts = ports?.filter((port) =>
          submitData.originPortIds?.includes(port.portID),
        );
      }
      if (isPort(submitData.destination)) {
        quoteRequest.destinationPorts = [submitData.destination] as Port[];
      } else if (isAddress(submitData.destination)) {
        quoteRequest.destinationAddress = submitData.destination as Address;
        quoteRequest.destinationPorts = ports?.filter((port) =>
          submitData.destinationPortIds?.includes(port.portID),
        );
      }

      if (submitData.transport !== TransportMode.FCL) {
        submitData.cargo.forEach((packageItem: QuotationCargoFormData) => {
          const { quantity, weight, width, height, length, ...rest } =
            packageItem;
          for (let i = 0; i < quantity; i++) {
            quoteRequest.packages.push({
              ...rest,
              weight: parseFloat(weight as string),
              width: parseFloat(width as string),
              height: parseFloat(height as string),
              length: parseFloat(length as string),
              freightID: uuidv4(),
              freightType: FreightType.Package,
            });
          }
        });
      } else {
        quoteRequest.container = [];
        if (submitData.containers != null) {
          for (let i = 0; i < submitData.containers?.quantity; i++) {
            quoteRequest.container.push({
              containerSizeType: submitData.containers?.containerSizeType,
              weight: submitData.containers?.weight,
              weightUnit: submitData.containers?.weightUnit,
            });
          }
        }
      }

      const response = await postApi('quotes', quoteRequest);
      if (!response.ok) {
        errorToast({
          title: getHttpStatusDescription(response.status),
        });
        return;
      }
      const quotation = (await response.json()) as QuoteResponse;
      const status = quotation.quoteStatus;
      if (status.status === Status.LCL_ERROR) {
        const lclErrorMessage = (
          <p>
            Dimensions, weight or volume too high for LCL. <br /> max:{' '}
            {status.maxLengthPackagesCm}x{status.maxWidthPackagesCm}x
            {status.maxHeightPackagesCm}cm (LWH) (volume:{' '}
            {status.maxVolumePackagesCbm}cbm); {status.maxWeightPackagesKg}kg
          </p>
        );
        errorToast({
          title: lclErrorMessage,
        });
        return;
      }
      if (status.status === Status.AIR_FREIGHT_ERROR) {
        const airFreightErrorMessage = (
          <p>
            Dimensions, weight or volume too high for air freight. <br /> max:{' '}
            {status.maxLengthPackagesCm}x{status.maxWidthPackagesCm}x
            {status.maxHeightPackagesCm}cm (LWH); {status.maxWeightPackagesKg}kg
          </p>
        );
        errorToast({
          title: airFreightErrorMessage,
        });
        return;
      }
      if (status.status === Status.NOT_FOUND || response.status === 204) {
        errorToast({
          title: 'No quotations found for the requested details.',
        });
        return;
      }
      setQuotation(
        quotation.quote,
        quotation.quotingSource,
        quotation.ifApplicables,
        quotation.rateType,
      );
    },
    [
      setFormData,
      lengthUnits,
      weightUnits,
      currencies?.data,
      postApi,
      setQuotation,
      ports,
      errorToast,
    ],
  );

  const setTransportMode = useCallback(
    (value: number) => {
      setValue('origin', undefined);
      setValue('destination', undefined);

      if (formData) {
        setFormData({
          ...formData,
          transport: value,
          origin: undefined,
          originValue: '',
          destination: undefined,
          destinationValue: '',
          containers: undefined,
        });
      }
    },
    [formData, setFormData, setValue],
  );

  useEffect(() => {
    if (formData == null) {
      reset(defaultValues);
    }
  }, [defaultValues, formData, reset]);

  useEffect(() => {
    if (originAddressPorts && originAddressPorts.length > 0) {
      originAdressPortsRef.current?.setValue(
        originAddressPorts.map((port) => ({
          label: getPortCode(port),
          value: port.portID,
        })),
        'select-option',
      );
    }
  }, [originAddressPorts]);

  useEffect(() => {
    if (destinationAddressPorts && destinationAddressPorts.length > 0) {
      destinationAdressPortsRef.current?.setValue(
        destinationAddressPorts.map((port) => ({
          label: getPortCode(port),
          value: port.portID,
        })),
        'select-option',
      );
    }
  }, [destinationAddressPorts]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Card spacing="7">
          <Heading as="h2" variant="h2">
            Transport & Currency
          </Heading>
          <SimpleGrid {...simpleGridProps}>
            <RadioFormInput
              label="Transport Type"
              accessor="transport"
              control={control}
              isRequired={true}
              isDisabled={isSubmitting}
              options={[
                { label: 'Air', value: TransportMode.Air },
                { label: 'LCL', value: TransportMode.LCL },
                { label: 'FCL', value: TransportMode.FCL },
              ]}
              setValueAs={(value) => {
                const intValue = parseInt(value as string);
                setTransportMode(intValue);
                return intValue;
              }}
            />
            <SelectFormInput
              label="Currency"
              accessor="currency"
              isRequired={true}
              isDisabled={isSubmitting}
              control={control}
              placeholder="Select currency"
              value={{
                label: currencies.data?.find(
                  (cur) => cur.currencyID === currency,
                )?.code,
                value: currencies.data?.find(
                  (cur) => cur.currencyID === currency,
                )?.currencyID,
              }}
              options={
                !currencies.isLoading && currencies.data
                  ? currencies.data.map((cur) => ({
                      label: cur.code,
                      value: cur.currencyID,
                    }))
                  : []
              }
              defaultValue={{
                label: currencies.data?.find(
                  (cur) => cur.currencyID === currency,
                )?.code,
                value: currencies.data?.find(
                  (cur) => cur.currencyID === currency,
                )?.currencyID,
              }}
            />
          </SimpleGrid>
          <Divider borderColor="grey.400" />
          <Heading as="h2" variant="h2">
            Route
          </Heading>
          <SimpleGrid {...simpleGridProps}>
            <GridItem colSpan={isAddress(origin) ? 1 : 2}>
              <Stack spacing={4}>
                <SearchInput
                  label="Origin"
                  accessor="origin"
                  register={register}
                  errors={errors}
                  items={originItems}
                  inputValue={formData?.originValue ?? ''}
                  isRequired={true}
                  isDisabled={isSubmitting}
                  isLoading={isOriginLoading || isPortsLoading}
                  placeholder="Company name, location... (powered by Google)"
                  onInputValueChange={(input) => {
                    setFormData({ ...formData!, originValue: input });
                  }}
                  onItemChange={(selection) => {
                    onPlaceSelection(selection, true);
                  }}
                  itemToString={placeToString}
                  displayItems={displayPlaceResults}
                />
                <PlaceDetails place={origin} />
              </Stack>
            </GridItem>
            {isAddress(origin) && formData?.transport !== TransportMode.FCL && (
              <MultiSelectFormInput
                forwardRef={originAdressPortsRef}
                label="Route via"
                accessor="originPortIds"
                control={control}
                noOptionsMessage={(obj) =>
                  obj?.inputValue === ''
                    ? 'Maximum of 3 routes selected'
                    : 'No results found'
                }
                filterOption={() => filterPortOptions(originAdressPortsRef)}
                options={
                  isLoadingOriginAddressPorts
                    ? []
                    : getPortOptions(origin as Address)
                }
                isLoading={isLoadingOriginAddressPorts}
              />
            )}
            <GridItem colSpan={isAddress(destination) ? 1 : 2}>
              <Stack spacing={4}>
                <SearchInput
                  label="Destination"
                  accessor="destination"
                  register={register}
                  errors={errors}
                  items={destinationItems}
                  inputValue={formData?.destinationValue ?? ''}
                  isRequired={true}
                  isDisabled={isSubmitting}
                  isLoading={isDestinationLoading || isPortsLoading}
                  placeholder="Company name, location... (powered by Google)"
                  onInputValueChange={(input) =>
                    setFormData({ ...formData!, destinationValue: input })
                  }
                  onItemChange={(selection) => {
                    onPlaceSelection(selection, false);
                  }}
                  itemToString={placeToString}
                  displayItems={displayPlaceResults}
                />
                <PlaceDetails place={destination} />
              </Stack>
            </GridItem>
            {isAddress(destination) &&
              formData?.transport !== TransportMode.FCL && (
                <Stack>
                  <MultiSelectFormInput
                    forwardRef={destinationAdressPortsRef}
                    label="Route via"
                    accessor="destinationPortIds"
                    control={control}
                    noOptionsMessage={(obj) =>
                      obj?.inputValue === ''
                        ? 'Maximum of 3 routes selected'
                        : 'No results found'
                    }
                    filterOption={() =>
                      filterPortOptions(destinationAdressPortsRef)
                    }
                    options={
                      isLoadingDestinationAddressPorts
                        ? []
                        : getPortOptions(destination as Address)
                    }
                    isLoading={isLoadingDestinationAddressPorts}
                  />
                </Stack>
              )}
          </SimpleGrid>
        </Card>
        {transport === TransportMode.FCL ? (
          <MainCard heading="Cargo Details" spacing="8">
            <ContainerInput
              control={control}
              defaultValue={containers}
              isDisabled={isSubmitting}
              value={containers}
              gridProps={simpleGridProps}
              weightUnits={weightUnits}
              register={register}
              containerSizeTypes={containerSizeTypes}
            />
          </MainCard>
        ) : (
          <MainCard heading="Cargo Details" spacing="8">
            <Grid
              templateColumns={{ base: 'repeat(1)', md: 'repeat(2,1fr)' }}
              gap={8}
              {...simpleGridProps}
            >
              {cargo.map(
                (packageItem: QuotationCargoFormData, index: number) => (
                  <React.Fragment key={'cargo-' + index}>
                    <CargoInput
                      accessor={`cargo[${index}]`}
                      control={control}
                      register={register}
                      errors={errors}
                      showDelete={cargo.length > 1}
                      defaultValue={packageItem}
                      lengthUnits={lengthUnits}
                      weightUnits={weightUnits}
                      onDelete={() => removePackage(index)}
                      value={packageItem}
                      isDisabled={isSubmitting}
                      onAdd={() => addPackage()}
                      showAddBtn={index === cargo.length - 1}
                    />
                    {onZoom && (
                      <GridItem marginTop="-7" colSpan={colSpanValue}>
                        <Button
                          leftIcon={<DeleteIcon w="4" h="4" />}
                          backgroundColor="grey.500"
                          size="xs"
                          marginTop="5"
                          isFullWidth
                          disabled={cargo.length <= 1}
                          onClick={() => removePackage(index)}
                        >
                          Delete
                        </Button>
                      </GridItem>
                    )}
                    <GridItem marginTop="-4" colSpan={colSpanValue}>
                      <Divider borderColor="grey.400" py="2" />
                    </GridItem>
                  </React.Fragment>
                ),
              )}
              <Stack justifyContent="end">
                <HStack>
                  <InfoItem
                    title="Total Volume"
                    value={valueOrDefault(
                      volumeReduce(cargo).toFixed(3),
                      'cbm',
                      '-',
                    )}
                  />
                </HStack>
              </Stack>
              <Stack justifyContent="end">
                <HStack>
                  <InfoItem
                    title="Total Weight"
                    value={valueOrDefault(
                      weightReduce(cargo).toFixed(2),
                      selectWeightUnitString(cargo),
                      '-',
                    )}
                  />
                </HStack>
              </Stack>
            </Grid>
          </MainCard>
        )}
        <Card
          direction={onMobile ? 'column' : 'row'}
          justify={!onMobile ? 'space-between' : ''}
          align={!onMobile ? 'center' : ''}
        >
          <Button
            type="submit"
            colorScheme="blue"
            isDisabled={
              isLoadingDestinationAddressPorts || isLoadingOriginAddressPorts
            }
            isLoading={isSubmitting}
          >
            Request Quote
          </Button>
          <Button
            type="button"
            variant="ghost"
            colorScheme="blue"
            onClick={() => {
              setFormData(undefined);
              reset(defaultValues);
            }}
            size="xs"
            isDisabled={isSubmitting}
          >
            Reset
          </Button>
        </Card>
      </Stack>
    </form>
  );
};

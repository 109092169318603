import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useApi } from '../../../../providers/ApiProvider';
import { useData } from '../../../../providers/DataProvider';
import { Port, TransportMode } from '../../../../types';
import { MultiselectOption } from '../../../../types/MultiSelectOption';
import { formatAirportsToOptions } from '../../../../utils/model/formatAirportsToOptions';
import { formatCurrenciesToOptions } from '../../../../utils/model/formatCurrenciesToOptions';
import { formatPortsToOptions } from '../../../../utils/model/formatPortsToOptions';

export const useTariffEditOptions = (
  countryId: string,
  transportMode: TransportMode,
) => {
  const { getApi } = useApi();
  const { currencies, countries } = useData();

  const [formattedCurrencies, setFormattedCurrencies] = useState<
    MultiselectOption[]
  >([]);

  const selectedCountry = countries.data?.find(
    (item) => item.countryID === countryId,
  );

  const { isFetching, data: allports } = useQuery<Port[] | undefined>(
    ['ports', selectedCountry],
    async () => {
      const result = await getApi(
        `ports?countryId=${selectedCountry?.countryID ?? null}`,
      );

      if (result.ok) {
        return (await result.json()) as Port[];
      }
    },
    { refetchOnWindowFocus: false },
  );

  const formattedPorts = useMemo<MultiselectOption[]>(() => {
    if (transportMode === TransportMode.Air) {
      return formatAirportsToOptions(allports, selectedCountry);
    } else return formatPortsToOptions(allports, selectedCountry);
  }, [allports, selectedCountry, transportMode]);

  useEffect(() => {
    setFormattedCurrencies(formatCurrenciesToOptions(currencies));
  }, [currencies]);

  return {
    isFetching,
    allports,
    formattedCurrencies,
    formattedPorts,
    selectedCountry,
  };
};

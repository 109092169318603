import { AddIcon } from '@chakra-ui/icons';
import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { Card, HeadingCard } from '../../components/Layout';
import { useErrorToast } from '../../hooks/useErrorToast';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import { useApi } from '../../providers/ApiProvider';
import {
  ChargeCode,
  Country,
  Currency,
  DetailedPort,
  System,
} from '../../types';
import {
  MasterData,
  MasterDataType,
  SelectedSystem,
} from '../../types/MasterData';
import { getHttpStatusDescription } from '../../utils/httpStatus';
import { getUnlocode } from '../../utils/model/getCargonerdsPort';
import { MasterDataTable } from './components/MasterDataTable';
import { UpsertMasterDataModal } from './components/UpsertMasterDataModal';

export const getMasterDataTypeSingular = (masterDataType: MasterDataType) => {
  switch (masterDataType) {
    case MasterDataType.COUNTRIES:
      return 'Country';
    case MasterDataType.CURRENCIES:
      return 'Currency';
    case MasterDataType.SEAPORTS:
    case MasterDataType.AIRPORTS:
      return 'Port';
    case MasterDataType.CHARGE_CODES:
      return 'Charge Code';
  }
};

const errorTitle = 'Saving your changes failed, please try again';

export const MasterDataPage: FC = () => {
  const [masterDataType, setMasterDataType] = useState<MasterDataType>(
    MasterDataType.SEAPORTS,
  );
  const [isCreateModal, setIsCreateModal] = useState(true);
  const [editItem, setEditItem] = useState<MasterData | null>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { putApi, getApi, postApi, deleteApi } = useApi();
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  const ports = useQuery<DetailedPort[]>('ports', async () => {
    const result = await getApi('ports/detailed?onlyActive=false');

    if (result.ok) {
      const res = (await result.json()) as DetailedPort[];
      return res.sort((c1, c2) =>
        (getUnlocode(c1.portCodes)?.code ?? '').localeCompare(
          getUnlocode(c2.portCodes)?.code ?? '',
        ),
      );
    }

    return [];
  });

  const countries = useQuery<Country[]>('countries', async () => {
    const result = await getApi('countries?onlyActive=false');

    if (result.ok) {
      const res = (await result.json()) as Country[];
      return res.sort((c1, c2) => c1.name.localeCompare(c2.name));
    }

    return [];
  });

  const currencies = useQuery<Currency[]>('currencies', async () => {
    const result = await getApi('currencies');

    if (result.ok) {
      const res = (await result.json()) as Currency[];
      return res.sort((c1, c2) => c1.name.localeCompare(c2.name));
    }

    return [];
  });

  const chargeCodes = useQuery<ChargeCode[]>('chargeCodes', async () => {
    const result = await getApi('charge-codes?onlyActive=false');

    if (result.ok) {
      return (await result.json()) as ChargeCode[];
    }

    return [];
  });

  const systems = useQuery<System[]>('systems', async () => {
    const result = await getApi('systems');

    if (result.ok) {
      return (await result.json()) as System[];
    }

    return [];
  });

  const onEditMasterData = (dataType: MasterDataType, item: MasterData) => {
    setIsCreateModal(false);
    setMasterDataType(dataType);
    setEditItem(item);
    onOpen();
  };

  const [chargeCodesSelectedSystemCode, setChargeCodesSelectedSystemCode] =
    useState<SelectedSystem>();
  const onChargeCodesSelectedSystemChanged = (systemCode: SelectedSystem) => {
    setChargeCodesSelectedSystemCode(systemCode);
  };

  const handleResponse = async (
    response: Response | null,
    successText: string,
  ) => {
    if (response) {
      if (response.ok) {
        successToast({
          title: successText,
        });
        onClose();
      } else {
        errorToast({
          title:
            response.status === 409
              ? await response.text()
              : getHttpStatusDescription(response.status),
        });
      }
    } else {
      errorToast({
        title: 'Something went wrong',
      });
    }
  };

  const onDelete = async (itemId: string, type: MasterDataType) => {
    let response = null;

    try {
      switch (type) {
        case MasterDataType.COUNTRIES:
          response = await deleteApi(`countries/${itemId}`);
          countries.refetch();
          break;
        case MasterDataType.CURRENCIES:
          response = await deleteApi(`currencies/${itemId}`);
          currencies.refetch();
          break;
        case MasterDataType.SEAPORTS:
        case MasterDataType.AIRPORTS:
          response = await deleteApi(`ports/${itemId}`);
          ports.refetch();
          break;
        case MasterDataType.CHARGE_CODES:
          response = await deleteApi(`charge-codes/${itemId}`);
          chargeCodes.refetch();
          break;
      }
    } catch (e) {
      errorToast({ title: errorTitle });
      return false;
    }

    await handleResponse(response, 'Item has been deleted successfully');
  };

  const onCreate = async (item: MasterData, type: MasterDataType) => {
    let response = null;
    try {
      switch (type) {
        case MasterDataType.COUNTRIES:
          response = await postApi('countries', item);
          countries.refetch();
          break;
        case MasterDataType.CURRENCIES:
          response = await postApi('currencies', item);
          currencies.refetch();
          break;
        case MasterDataType.SEAPORTS:
        case MasterDataType.AIRPORTS:
          response = await postApi('ports', item);
          ports.refetch();
          break;
        case MasterDataType.CHARGE_CODES:
          response = await postApi('charge-codes', item);
          chargeCodes.refetch();
          break;
      }
    } catch (e) {
      errorToast({ title: errorTitle });
      return false;
    }
    await handleResponse(response, 'Item created');
    return response?.ok ?? false;
  };

  const onUpdate = async (
    newData: MasterData,
    oldData: MasterData,
    type: MasterDataType,
  ) => {
    let response = null;
    try {
      switch (type) {
        case MasterDataType.COUNTRIES:
          const updatedCountry: Country = {
            ...(oldData as Country),
            ...(newData as Country),
          };
          response = await putApi(
            `countries/${updatedCountry.countryID}`,
            updatedCountry,
          );
          countries.refetch();
          break;
        case MasterDataType.CURRENCIES:
          const updatedCurrency: Currency = {
            ...(oldData as Currency),
            ...(newData as Currency),
          };
          response = await putApi(
            `currencies/${updatedCurrency.currencyID}`,
            updatedCurrency,
          );
          currencies.refetch();
          break;
        case MasterDataType.SEAPORTS:
        case MasterDataType.AIRPORTS:
          const updatedPort: DetailedPort = {
            ...(oldData as DetailedPort),
            ...(newData as DetailedPort),
          };
          response = await putApi(`ports/${updatedPort.portID}`, updatedPort);
          ports.refetch();
          break;
        case MasterDataType.CHARGE_CODES:
          const updatedChargeCode: ChargeCode = {
            ...(oldData as ChargeCode),
            ...(newData as ChargeCode),
          };
          response = await putApi(
            `charge-codes/${updatedChargeCode.chargeCodeID}`,
            updatedChargeCode,
          );
          chargeCodes.refetch();
          break;
      }
    } catch (e) {
      errorToast({ title: errorTitle });
      return false;
    }
    await handleResponse(response, 'Item has been updated');

    return response?.ok ?? false;
  };

  const onUpsertModalClose = () => {
    onClose();
    setEditItem(null);
  };

  return (
    <>
      <UpsertMasterDataModal
        isOpen={isOpen}
        onClose={onUpsertModalClose}
        masterDataType={masterDataType}
        isCreate={isCreateModal}
        editItem={editItem}
        onDelete={onDelete}
        onCreate={onCreate}
        onUpdate={onUpdate}
        chargeCodesSelectedSystem={chargeCodesSelectedSystemCode}
        systems={systems.data ?? []}
      />
      <HeadingCard
        heading="Master Data"
        display="flex"
        flexDir="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          colorScheme="secondary"
          size="xs"
          overflow="wrap"
          leftIcon={<AddIcon />}
          onClick={() => {
            setIsCreateModal(true);
            onOpen();
          }}
        >
          {`Add ${getMasterDataTypeSingular(masterDataType)}`}
        </Button>
      </HeadingCard>
      <Card spacing="8" overflowY="hidden" minHeight="30rem">
        <HStack spacing="6">
          <Button
            background={
              masterDataType === MasterDataType.SEAPORTS ? 'grey.500' : 'auto'
            }
            _hover={{ background: 'grey.400' }}
            onClick={() => setMasterDataType(MasterDataType.SEAPORTS)}
          >
            Sea Ports
          </Button>
          <Button
            background={
              masterDataType === MasterDataType.AIRPORTS ? 'grey.500' : 'auto'
            }
            _hover={{ background: 'grey.400' }}
            onClick={() => setMasterDataType(MasterDataType.AIRPORTS)}
          >
            Air Ports
          </Button>
          <Button
            background={
              masterDataType === MasterDataType.COUNTRIES ? 'grey.500' : 'auto'
            }
            _hover={{ background: 'grey.400' }}
            onClick={() => setMasterDataType(MasterDataType.COUNTRIES)}
          >
            Countries
          </Button>
          <Button
            background={
              masterDataType === MasterDataType.CURRENCIES ? 'grey.500' : 'auto'
            }
            _hover={{ background: 'grey.400' }}
            onClick={() => setMasterDataType(MasterDataType.CURRENCIES)}
          >
            Currencies
          </Button>
          <Button
            background={
              masterDataType === MasterDataType.CHARGE_CODES
                ? 'grey.500'
                : 'auto'
            }
            _hover={{ background: 'grey.400' }}
            onClick={() => setMasterDataType(MasterDataType.CHARGE_CODES)}
          >
            Charge Codes
          </Button>
        </HStack>
        <MasterDataTable
          masterDataType={masterDataType}
          onEditMasterData={onEditMasterData}
          ports={ports}
          countries={countries}
          currencies={currencies}
          chargeCodes={chargeCodes}
          systems={systems}
          onChargeCodesSelectedSystemChanged={
            onChargeCodesSelectedSystemChanged
          }
        />
      </Card>
    </>
  );
};

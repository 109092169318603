import { DeleteIcon, AddIcon } from '@chakra-ui/icons';
import { Button, Flex } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { DataTable } from '../../../../components/DataTable';
import { MainCard } from '../../../../components/Layout';

interface TariffTableProps {
  rows: any;
  columns: any;
  isLoading: boolean;
  fetchFailed: boolean;
  statusCode: number;
  isEdit: boolean;
  handleEditClick?: () => void;
  onAddRow: () => void;
  onDeleteRow: () => void;
  onAddColumn: () => void;
  onDeleteColumn: () => void;
}

export const TariffTable: FunctionComponent<TariffTableProps> = ({
  rows,
  columns,
  isLoading,
  fetchFailed,
  statusCode,
  isEdit,
  onAddRow,
  onDeleteRow,
  onAddColumn,
  onDeleteColumn,
}) => (
  <MainCard>
    <Flex>
      <Flex flexDirection="column" width="100%" minWidth="0">
        <DataTable
          data={rows}
          columns={columns}
          isLoading={isLoading}
          fetchFailed={fetchFailed}
          statusCode={statusCode}
          enableFilters={false}
          sizeOfPage={() => 100}
          variant="default"
          minHeight="20rem"
          narrowRows={isEdit}
          disableMargin={true}
        />
        {isEdit && (
          <Flex justifyContent="center" alignItems="center">
            <Button variant="unstyled" onClick={onAddRow}>
              <AddIcon w="4" h="4" color="blue.500" />
            </Button>
            <Button variant="unstyled" onClick={onDeleteRow}>
              <DeleteIcon w="4" h="4" color="red.500" />
            </Button>
          </Flex>
        )}
      </Flex>
      {isEdit && (
        <Flex justifyContent="center" alignItems="center" minWidth="80px">
          <Button variant="unstyled" onClick={onAddColumn}>
            <AddIcon w="4" h="4" color="blue.500" />
          </Button>
          <Button variant="unstyled" onClick={onDeleteColumn}>
            <DeleteIcon w="4" h="4" color="red.500" />
          </Button>
        </Flex>
      )}
    </Flex>
  </MainCard>
);

import { chakra, HTMLChakraProps } from '@chakra-ui/system';

export const TruckIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 24 24" {...props}>
    <g
      id="Icons/Truck"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M19.2727273,8.75 L16.5454545,8.75 L16.5454545,5 L3.81818182,5 C2.81818182,5 2,5.84375 2,6.875 L2,17.1875 L3.81818182,17.1875 C3.81818182,18.74375 5.03636364,20 6.54545455,20 C8.05454545,20 9.27272727,18.74375 9.27272727,17.1875 L14.7272727,17.1875 C14.7272727,18.74375 15.9454545,20 17.4545455,20 C18.9636364,20 20.1818182,18.74375 20.1818182,17.1875 L22,17.1875 L22,12.5 L19.2727273,8.75 Z M18.8181818,10.15625 L20.6,12.5 L16.5454545,12.5 L16.5454545,10.15625 L18.8181818,10.15625 Z M6.54545455,18.125 C6.04545455,18.125 5.63636364,17.703125 5.63636364,17.1875 C5.63636364,16.671875 6.04545455,16.25 6.54545455,16.25 C7.04545455,16.25 7.45454545,16.671875 7.45454545,17.1875 C7.45454545,17.703125 7.04545455,18.125 6.54545455,18.125 Z M8.56363636,15.3125 C8.06363636,14.740625 7.35454545,14.375 6.54545455,14.375 C5.73636364,14.375 5.02727273,14.740625 4.52727273,15.3125 L3.81818182,15.3125 L3.81818182,6.875 L14.7272727,6.875 L14.7272727,15.3125 L8.56363636,15.3125 Z M17.4545455,18.125 C16.9545455,18.125 16.5454545,17.703125 16.5454545,17.1875 C16.5454545,16.671875 16.9545455,16.25 17.4545455,16.25 C17.9545455,16.25 18.3636364,16.671875 18.3636364,17.1875 C18.3636364,17.703125 17.9545455,18.125 17.4545455,18.125 Z"
        id="Shape"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </g>
  </chakra.svg>
);

import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react';
import { FunctionComponent, useMemo, useState } from 'react';
import { Column, FilterProps } from 'react-table';
import {
  isIfApplicableAmountCalculator,
  UpsertIfApplicableRate,
} from '../pages/ChargesPage/LocalPage/components/UpsertIfApplicableRate';
import { TableActionButtonProps } from '../theme/components';
import { LocalCharge, TransportMode } from '../types';
import {
  IfApplicable,
  IfApplicableCalculatorType,
  IfApplicableUnitType,
} from '../types/IfApplicable';
import { toDisplayDateFormat } from '../utils/formatter';
import { getTextWidth } from '../utils/textWidth';
import { ActionModal } from './ActionModal';
import { DataTable } from './DataTable';
import { SelectColumnFilter } from './DataTable/SelectColumnFilter';
import { conditionalHeader } from './DataTable/utils/dataTableUtils';
import { EditIcon } from './Icons';

interface IfApplicableTableProps {
  ifApplicables: IfApplicable[];
  isLoading: boolean;
  renderType: TransportMode;
  showActionColumn?: boolean;
  onDelete?: (rateId: string) => Promise<boolean>;
  onEdit?: (submitData: IfApplicable) => Promise<boolean>;
  hasFilters?: boolean;
  canvasContext: CanvasRenderingContext2D | null;
}

export const mapCalculatorHeader: (
  calculator: IfApplicableCalculatorType,
) => string = (calculator: IfApplicableCalculatorType) => {
  switch (calculator) {
    case IfApplicableCalculatorType.FLT:
      return 'FLT: Flat calculator';
    case IfApplicableCalculatorType.UNT:
      return 'UNT: Unit calculator';
    case IfApplicableCalculatorType.MPU:
      return 'MPU: Minimum or per Unit calculator';
    case IfApplicableCalculatorType.CMB:
      return 'CMB: Sliding or per unit with base';
    case IfApplicableCalculatorType.PER:
      return 'PER: Percentage calculator';
  }
};

export const mapUnitHeader = (unit?: IfApplicableUnitType) => {
  switch (unit) {
    case IfApplicableUnitType.KG:
      return 'KG Chargeable';
    case IfApplicableUnitType.HB:
      return 'HB';
    case IfApplicableUnitType.KG100:
      return '100 KG';
    case IfApplicableUnitType.Percent:
      return 'Percent';
    case IfApplicableUnitType.AdditionalLine:
      return 'Additional line (customs)';
    case IfApplicableUnitType.Container:
      return 'Container';
    case IfApplicableUnitType.Kg1000:
      return '1000 Kg (ton)';
    case IfApplicableUnitType.M3Chargeable:
      return 'M3 Chargeable (W/M)';
    case IfApplicableUnitType.Package:
      return 'Package';
    case IfApplicableUnitType.TEU:
      return 'TEU';
    default:
      return '';
  }
};

const amountTableAccessor = (info: IfApplicable, renderType: TransportMode) => {
  if (isIfApplicableAmountCalculator(info.calculator)) {
    if (info.calculator === IfApplicableCalculatorType.PER) {
      return `${info.amount?.toFixed(2)} %`;
    }
    return info.amount?.toFixed(2);
  }
  if (renderType === TransportMode.FCL) {
    return null;
  }
  return info.amount?.toFixed(2);
};

export const IfApplicableTable: FunctionComponent<IfApplicableTableProps> = ({
  ifApplicables,
  renderType,
  showActionColumn = false,
  onDelete,
  onEdit,
  isLoading,
  hasFilters = false,
  canvasContext,
}) => {
  const [rate, setRate] = useState<IfApplicable | null>(null);
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // @ts-ignore
  const ifApplicableColumns = useMemo<Column<IfApplicable>[]>(() => {
    const isAir: boolean = renderType === TransportMode.Air;
    const isFcl: boolean = renderType === TransportMode.FCL;

    return [
      {
        Header: 'Code',
        accessor: (info) => info.chargeCode.code,
        Filter: (columns: FilterProps<LocalCharge>) => (
          <SelectColumnFilter column={columns.column} />
        ),
      },
      {
        Header: 'Description',
        accessor: (info) => info.chargeCode.name,
        disableFilters: true,
        width: Math.max(
          ...ifApplicables.map((ifApplicable) =>
            getTextWidth({
              canvasContext: canvasContext,
              text: ifApplicable.chargeCode.name,
              minLength: 150,
            }),
          ),
        ),
      },
      {
        Header: 'Origin',
        accessor: (info) =>
          info.originPort
            ? `${info.originPort.country?.code ?? ''}${
                info.originPort.unloCode
              }`
            : info.origin?.code,
        Filter: (columns: FilterProps<LocalCharge>) => (
          <SelectColumnFilter column={columns.column} />
        ),
      },
      {
        Header: 'Destination',
        accessor: (info) =>
          info.destinationPort
            ? `${info.destinationPort.country?.code ?? ''}${
                info.destinationPort.unloCode
              }`
            : info.destination?.code,
        Filter: (columns: FilterProps<LocalCharge>) => (
          <SelectColumnFilter column={columns.column} />
        ),
      },
      ...conditionalHeader<IfApplicable>(
        {
          Header: 'Breakpoint from',
          accessor: (info) => info.breakpointFrom,
          disableFilters: true,
        },
        isAir,
      ),
      ...conditionalHeader<IfApplicable>(
        {
          Header: 'Breakpoint to',
          accessor: (info) => info.breakpointTo,
          disableFilters: true,
        },
        isAir,
      ),
      {
        Header: 'Calculator',
        accessor: (info) => mapCalculatorHeader(info.calculator),
        disableFilters: true,
      },
      {
        Header: 'Unit',
        accessor: (info) => mapUnitHeader(info.unitType),
        disableFilters: true,
      },
      {
        Header: 'Currency',
        accessor: (info) => info.currency?.code,
        disableFilters: true,
      },
      {
        Header: 'Minimum',
        accessor: (info) =>
          info.calculator !== IfApplicableCalculatorType.FLT
            ? info.minimum?.toFixed(2)
            : null,
        disableFilters: true,
      },
      {
        Header: 'Amount',
        accessor: (info) => amountTableAccessor(info, renderType),
        disableFilters: true,
      },
      ...conditionalHeader<IfApplicable>(
        {
          Header: '20GP',
          accessor: (info) =>
            !isIfApplicableAmountCalculator(info.calculator)
              ? info.amount?.toFixed(2)
              : null,
          disableFilters: true,
        },
        isFcl,
      ),
      ...conditionalHeader<IfApplicable>(
        {
          Header: '40GP',
          accessor: (info) =>
            !isIfApplicableAmountCalculator(info.calculator)
              ? info.fourtyFeetContainerAmount?.toFixed(2)
              : null,
          disableFilters: true,
        },
        isFcl,
      ),
      ...conditionalHeader<IfApplicable>(
        {
          Header: '40HC',
          accessor: (info) =>
            !isIfApplicableAmountCalculator(info.calculator)
              ? info.fourtyFeetHCContainerAmount?.toFixed(2)
              : null,
          disableFilters: true,
        },
        isFcl,
      ),
      {
        Header: 'Valid From',
        accessor: (info) =>
          info.validFrom && toDisplayDateFormat(info.validFrom),
        disableFilters: true,
      },
      {
        Header: 'Valid To',
        accessor: (info) => info.validTo && toDisplayDateFormat(info.validTo),
        disableFilters: true,
      },
      {
        Header: 'Comment',
        accessor: (info) => <Box overflowWrap="anywhere">{info.comment}</Box>,
        disableFilters: true,
      },
    ];
  }, [canvasContext, ifApplicables, renderType]);

  const actionColumn: Column<IfApplicable> = useMemo(
    () => ({
      Header: '',
      id: 'action',
      accessor: (rowRate: IfApplicable) => (
        <Flex w="100%" justifyContent="flex-end">
          <Button
            {...TableActionButtonProps}
            onClick={() => {
              setRate(rowRate);
              onOpen();
            }}
            rightIcon={<EditIcon w="4" h="4" />}
          >
            Edit
          </Button>
        </Flex>
      ),
      disableFilters: true,
    }),
    [onOpen],
  );

  const newColumns = useMemo(
    () =>
      showActionColumn
        ? [...ifApplicableColumns, actionColumn]
        : ifApplicableColumns,
    [showActionColumn, ifApplicableColumns, actionColumn],
  );

  return (
    <>
      {rate && (
        <ActionModal
          onCancel={() => onDeleteClose()}
          onSuccess={() => {
            onDeleteClose();
            onClose();
          }}
          isOpen={isDeleteOpen}
          onActionClick={() => onDelete?.(rate.infoId)}
          modalText="Do you really want to delete this rate?"
          modalHeader="Delete Rate"
          align="center"
        />
      )}
      {rate && (
        <UpsertIfApplicableRate
          isOpen={isOpen}
          onClose={onClose}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onSave={onEdit ? onEdit : async (_data: IfApplicable) => true}
          rate={rate}
          isCreate={rate.infoId === ''}
          onDeleteOpen={onDeleteOpen}
        />
      )}
      <DataTable
        data={ifApplicables}
        columns={newColumns}
        isLoading={isLoading}
        fetchFailed={false}
        variant={showActionColumn ? 'sticky' : 'fadedLight'}
        statusCode={0}
        enableFilters={hasFilters}
        minHeight={hasFilters ? '20rem' : 'auto'}
      />
    </>
  );
};

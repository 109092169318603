import { useDisclosure, Text } from '@chakra-ui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CancelModal } from '../../../components/Modals/CancelModal';
import { SaveChangesModal } from '../../../components/Modals/SaveChangesModal';
import { NotificationBox } from '../../../components/Output/NotificationBox';
import { useDecoratedApi } from '../../../hooks/api/useDecoratedApi';
import { useErrorToast } from '../../../hooks/useErrorToast';
import { useSuccessToast } from '../../../hooks/useSuccessToast';
import { useTariffCreateStore } from '../../../hooks/useTariffCreateStore';
import { HttpMethod } from '../../../providers/ApiProvider';
import { useData } from '../../../providers/DataProvider';
import { QuoteNotification, QuoteNotificationType } from '../../../types';
import { MethodTariff } from '../../../types/MethodTariff';
import { TariffTrucking } from '../../../types/api/TariffTrucking';
import { API_ROUTES } from '../../../utils/apiRoutes';
import { getDirectionType } from '../../../utils/model/getDirectionType';
import { transformCreateFormsToTariffTrucking } from '../../../utils/model/transformCreateFormsToTariffTrucking';
import { routes } from '../../../utils/routes';
import { TariffMetaInformation } from '../TariffPage/components/TariffMetaInformation';
import { TariffTable } from '../TariffPage/components/TariffTable';
import { useDynamicTariffTableForm } from '../TariffPage/hooks/useDynamicTariffTableForm';
import { TariffCreateRatesHeading } from './components/TariffCreateRatesHeading';
import { getRateFormValuesSorted } from './utils/getRateFormValuesSorted';

export const TariffCreateRatesPage: FunctionComponent = () => {
  const cancelModal = useDisclosure();
  const saveModal = useDisclosure();
  const [notificationsList, setNotificationsList] =
    useState<QuoteNotification[]>();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const {
    metaForm,
    country,
    selectedPorts,
    selectedAirports,
    setRateForm,
    rateForm,
  } = useTariffCreateStore();

  const navigate = useNavigate();
  const location = useLocation();
  const { currencies } = useData();

  const { sendRequest, loading } = useDecoratedApi<TariffTrucking, {}>(
    'Failed to create a new tariff',
    HttpMethod.POST,
  );

  const {
    onAddRow,
    onDeleteRow,
    onDeleteColumn,
    onAddColumn,
    getValues,
    handleSubmit,
    rowCount,
    columnCount,
    rows,
    columns,
  } = useDynamicTariffTableForm(
    metaForm?.method || 0,
    metaForm?.calculator || 0,
    country?.code || '',
    undefined,
    rateForm,
    true,
  );

  const getDirections = () =>
    metaForm?.directions.map((direction) => getDirectionType(direction));

  const onFormSave = () => {
    handleSubmit(
      () => {
        setNotificationsList([]);
        if (!metaForm || !country) {
          errorToast({
            title: 'There are form errors',
            description:
              'There are issues with the provided data, please check the Tariff table and the Meta information form',
          });
          return false;
        }
        const { zones, headers, cells } = getRateFormValuesSorted(
          getValues(),
          rowCount,
          columnCount,
        );
        const postBody = transformCreateFormsToTariffTrucking(
          metaForm,
          zones,
          headers,
          cells,
          country,
          currencies,
          metaForm?.transportType || 0,
          getDirections(),
          selectedPorts,
          selectedAirports,
        );
        sendRequest(API_ROUTES.tariffsTrucking(), postBody).then((response) => {
          if (response) {
            successToast({ title: 'New Tariff was successfully created!' });
            navigate(routes.charges.trucking);
          }
        });
      },
      (rateFormErrors) => {
        const zoneError = Object.keys(rateFormErrors).find((rateFieldKey) =>
          rateFieldKey.includes('zone-'),
        );
        if (zoneError && metaForm?.method === MethodTariff.ZipCodes) {
          setNotificationsList([
            {
              type: QuoteNotificationType.Error,
              message: (
                <Text>
                  Some of the ZIP codes you entered are not in the correct
                  format. Please ensure all ZIP codes follow the correct syntax
                  for {country?.name}.
                  {!!country?.zipFormats?.length ? (
                    <>
                      <br /> For reference, a valid ZIP code example is:
                    </>
                  ) : (
                    ''
                  )}{' '}
                  <b>
                    {country?.zipFormats
                      ?.map((zipExample) => `${zipExample}`)
                      .join(', ') || ''}
                  </b>
                  <br />
                  <br /> Kindly review your entries and try again.
                </Text>
              ),
              highlight:
                country?.zipFormats
                  ?.map((zipExample) => `${zipExample}`)
                  .join(', ') || '',
            },
          ]);
        }
        errorToast({
          title: 'There are form errors',
          description: 'Please check the form for any errors',
        });
        saveModal.onClose();
      },
    )();
  };

  useEffect(() => {
    if (!country || !country.countryID) {
      navigate(routes.charges.trucking);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelEdit = () => {
    navigate(routes.charges.trucking);
  };

  const handleBackClick = () => {
    setRateForm(getValues());
    navigate(routes.charges.tariffCreate, {
      state: { backTo: location.state?.backTo },
    });
  };

  return (
    <>
      <TariffCreateRatesHeading
        title={metaForm?.name || ''}
        onSave={saveModal.onOpen}
        onBack={handleBackClick}
        onCancel={cancelModal.onOpen}
      />
      {notificationsList?.length && (
        <NotificationBox
          type={QuoteNotificationType.Error}
          typeNotifications={notificationsList}
        />
      )}
      {metaForm && country && (
        <TariffMetaInformation
          metaInformation={metaForm}
          country={country}
          ports={[...selectedPorts, ...selectedAirports]}
        />
      )}
      <form>
        <TariffTable
          isLoading={loading}
          fetchFailed={false}
          statusCode={200}
          isEdit={true}
          rows={rows}
          columns={columns}
          onAddRow={onAddRow}
          onDeleteRow={onDeleteRow}
          onAddColumn={onAddColumn}
          onDeleteColumn={onDeleteColumn}
        />
      </form>
      <SaveChangesModal
        isOpen={saveModal.isOpen}
        onAction={onFormSave}
        onCancel={saveModal.onClose}
      />
      <CancelModal
        isOpen={cancelModal.isOpen}
        onAction={handleCancelEdit}
        onCancel={cancelModal.onClose}
      />
    </>
  );
};

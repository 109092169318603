import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/modal';
import { Box, Button, Text } from '@chakra-ui/react';
import { FunctionComponent, useState } from 'react';

interface ActionModalProps {
  onCancel: () => void;
  onSuccess?: () => void;
  onActionClick: () => Promise<boolean | void> | void;
  isOpen: boolean;
  modalText: string;
  modalHeader: string;
  parentLoading?: boolean;
  actionButtonText?: string;
  actionButtonColor?: string;
  align?: 'center' | 'unset';
}

export const ActionModal: FunctionComponent<ActionModalProps> = ({
  onCancel,
  onSuccess,
  onActionClick,
  isOpen,
  modalText,
  modalHeader,
  parentLoading,
  actionButtonColor = 'red',
  actionButtonText = 'Delete',
  children,
  align = 'center',
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onConfirmAction = async () => {
    setIsLoading(true);
    const success = await onActionClick();
    setIsLoading(false);

    if (success !== false && onSuccess) {
      onSuccess();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent bg="grey.800">
        <ModalHeader textAlign={align} justifyContent={align}>
          {modalHeader}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign={align} justifyContent={align}>
          <Text fontSize="sm">{modalText}</Text>
          {children != null && <Box mt={4}>{children}</Box>}
        </ModalBody>
        <ModalFooter justifyContent={align}>
          <Button
            variant="ghost"
            colorScheme="white"
            _hover={{ bgColor: 'grey.600' }}
            mr={3}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            colorScheme={actionButtonColor}
            isLoading={isLoading || parentLoading}
            onClick={onConfirmAction}
          >
            {actionButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import type { ChargeCode, Country, Currency, DetailedPort } from '.';

export enum MasterDataType {
  SEAPORTS,
  COUNTRIES,
  CURRENCIES,
  CHARGE_CODES,
  AIRPORTS,
}

export type MasterData = ChargeCode | Country | Currency | DetailedPort;

export type SelectedSystem = { name: string; code: string };

import { useNavigate } from 'react-router-dom';
import { useDecoratedApi } from '../../../../hooks/api/useDecoratedApi';
import { useSuccessToast } from '../../../../hooks/useSuccessToast';
import { HttpMethod } from '../../../../providers/ApiProvider';
import { TariffTrucking } from '../../../../types/api/TariffTrucking';
import { TariffTruckingDto } from '../../../../types/api/TariffTruckingDto';
import { API_ROUTES } from '../../../../utils/apiRoutes';
import { transformTariffTruckingDtoToCreateModel } from '../../../../utils/model/transformTariffTruckingDtoToCreateModel';
import { routes } from '../../../../utils/routes';
import { getRateFormValuesSorted } from '../../TariffCreateRatesPage/utils/getRateFormValuesSorted';
import { transformBracketsToRateForm } from '../utils/transformBracketsToRateForm';

export const useTariffDuplicate = () => {
  const successToast = useSuccessToast();

  const { sendRequest, loading } = useDecoratedApi(
    'Failed to create tariff',
    HttpMethod.POST,
  );

  const navigate = useNavigate();

  const duplicateResource = (rate: TariffTruckingDto | null | undefined) => {
    if (rate) {
      const formValues = transformBracketsToRateForm(rate);
      const rowCount = rate.methodBrackets.length;
      const columnCount = rate.weightBrackets.length;
      const { zones, headers, cells } = getRateFormValuesSorted(
        formValues,
        rowCount,
        columnCount,
      );
      const requestBody: TariffTrucking =
        transformTariffTruckingDtoToCreateModel(
          { ...rate, name: `${rate.name} (Copy)` },
          zones,
          headers,
          cells,
        );

      sendRequest(API_ROUTES.tariffsTrucking(), requestBody).then(
        (response) => {
          if (response) {
            successToast({
              title: 'Successfully created a copy of current tariff',
            });
            navigate(routes.charges.trucking);
          }
        },
      );
    }
  };
  return { duplicateResource, duplicateLoading: loading };
};

import { BracketTransportMethod } from '../../../../types/api/BracketTransportMethod';

export const EMPTY_METHOD_BRACKET_PLACEHOLDER: BracketTransportMethod = {
  bracketTransportMethodID: '00000000-0000-0000-0000-000000000000',
  rangeFrom: '',
  rangeTo: '',
  tariffTruckingID: '00000000-0000-0000-0000-000000000000',
  tariffTrucking: null,
  order: 0,
  rangeFromComplete: null,
  rangeToComplete: null,
};

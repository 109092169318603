import { MethodTariff } from '../../types/MethodTariff';
import { ApiTransportDirection } from '../../types/TransportDirection';
import { TariffTruckingDto } from '../../types/api/TariffTruckingDto';
import { CreateTariffMetaValues } from '../../types/forms/CreateTariffMetaValues';
import { normalizeEnumValue } from './normalizeEnumValue';

export const getMetaFromRate = (
  rate: TariffTruckingDto,
): CreateTariffMetaValues => {
  const method = normalizeEnumValue(MethodTariff, rate.method[0].toString());

  return {
    assignedAirports: [],
    method: method || 0,
    transportType: rate.transportMode || 0,
    validTo: new Date(rate.validTo),
    name: rate.name,
    currency: rate.currency.code,
    validFrom: new Date(rate.validFrom),
    calculator: rate.calculator,
    directions: rate.direction.map(
      (direction) => ApiTransportDirection[direction] as unknown as number,
    ),
    assignedPorts: rate.assignedPorts.map((port) => port.unloCode ?? ''),
    chargeableWeight: rate.chargeableWeightRatio ?? 0,
    chargeableRatio: 1,
    percentFuel: rate.percentFuel ?? 0,
  };
};
